import React, { useState } from "react";
import {
  RowType,
  BEScrollTable,
  DataRequest,
} from "@mesh/common-js-react/dist/Tables";
import {
  Button,
  Tab,
  Tabs,
  Theme,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";
import { TabOption, useMyOffersStore } from "./store";
import {
  FilterMap,
  useSearchLimitOrderView,
} from "./hooks/useSearchLimitOrderView";
import { limitOrderTypeToString } from "@mesh/common-js/dist/market/limitOrderType";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { limitOrderStateToString } from "@mesh/common-js/dist/market/limitOrderState";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { newUint32ListCriterion } from "@mesh/common-js/dist/search";
import { useLimitOrderController } from "./hooks/useLimitOrderStateController";
import { Criteria } from "@mesh/common-js/dist/search/Criteria";
import { LimitOrderState } from "@mesh/common-js/dist/market/limitOrder_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/src/i8n/timezone_pb";
import { useExchangeDashboardStore } from "../../store";

export const MyOffers = () => {
  const { searchMarketLimitOrderView, refresh } = useSearchLimitOrderView();
  const store = useMyOffersStore();

  return (
    <BEScrollTable
      requestFunction={async (request: DataRequest) => {
        try {
          const response = await searchMarketLimitOrderView(request);
          if (!response)
            return {
              models: [],
              total: 0,
            };
          return {
            models: response.getRecordsList(),
            total: response.getTotal(),
          };
        } catch (e) {
          console.error(e);
        }
        return {
          models: [],
          total: 0,
        };
      }}
      columns={columns}
      title={""}
      filters={(setCriteria, setQuery): React.ReactNode => (
        <Tabs
          textColor={"inherit"}
          value={store.selectedTab}
          onChange={async (e, v) => {
            const request = store.request
              .setCriteriaList([
                newUint32ListCriterion("state", FilterMap[v as TabOption]),
              ])
              .setQuery(new Query().setOffset(0).setLimit(10));
            setCriteria(request.getCriteriaList());
            setQuery(store.request.getQuery() ?? new Query());
            store.setRequest(request);
            store.setSelectedTab(v as TabOption);
          }}
        >
          <Tab
            sx={{
              textTransform: "capitalize",
            }}
            value={TabOption.MyOffers}
            label="Open Orders"
          />
          <Tab
            sx={{
              textTransform: "capitalize",
            }}
            value={TabOption.History}
            label="History"
          />
        </Tabs>
      )}
      interval={10}
      data={store.marketLimitOrderViewModels}
      total={store.total}
      criteria={new Criteria()}
      query={store.request.getQuery()}
      refresh={refresh}
      onPageChange={() => {
        let q = store.request.getQuery();
        if (!q) {
          q = new Query();
        }
        q.setOffset(q.getLimit() + q.getOffset());
        store.setRequest(store.request.setQuery());
      }}
      sx={(theme: Theme) => ({
        height: 260,
        ".header-cell": {
          backgroundColor: theme.palette.custom.cardInner,
          py: 1,
        },
      })}
    />
  );
};

const CancelButton = (props: { model: Model }) => {
  const { cancelLimitOrder } = useLimitOrderController();
  const store = useMyOffersStore();
  const exchangeStore = useExchangeDashboardStore();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      variant="outlined"
      disabled={
        store.cancelingOrder ||
        props.model.getState() != LimitOrderState.OPEN ||
        exchangeStore.loading
      }
      onClick={async () => {
        setLoading(true);
        await cancelLimitOrder(props.model);
        setLoading(false);
      }}
    >
      Cancel {loading && <CircularProgress sx={{ ml: 1 }} size={24} />}
    </Button>
  );
};

type ColumnType = Omit<
  Model.AsObject,
  "id" | "ownerid" | "stellarofferid" | "feeamount" | "vatrate" | "limitorderid"
> & {
  pair: string;
  cancel: boolean;
};

const columns: RowType<Model, ColumnType> = {
  pair: {
    title: "Pair",
    renderCell: (rowData: Model) => {
      return (
        <Typography>
          {rowData.getBaseamount()?.getToken()?.getCode()}/
          {rowData.getPrice()?.getToken()?.getCode()}
        </Typography>
      );
    },
  },
  number: {
    title: "Number",
    renderCell: (rowData: Model) => {
      return rowData.getNumber();
    },
  },
  type: {
    title: "Type",
    renderCell: (rowData: Model) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            className={limitOrderTypeToString(rowData.getType())}
            sx={(theme) => ({
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              "&.Sell": {
                backgroundColor: theme.palette.primary.main,
              },
              "&.Buy": {
                backgroundColor: theme.palette.secondary.main,
              },
            })}
          />
          <Typography>{limitOrderTypeToString(rowData.getType())}</Typography>
        </Box>
      );
    },
  },
  price: {
    title: "Price",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getPrice())} />
      );
    },
  },
  amountfilled: {
    title: "Amount Filled",
    renderCell: (rowData: Model) => {
      return (
        <Amount
          amount={AmountType.fromFutureAmount(rowData.getAmountfilled())}
        />
      );
    },
  },
  baseamount: {
    title: "Base Amount",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getBaseamount())} />
      );
    },
  },
  quoteamount: {
    title: "Quote Amount",
    renderCell: (rowData: Model) => {
      return (
        <Amount
          amount={AmountType.fromFutureAmount(rowData.getQuoteamount())}
        />
      );
    },
  },
  state: {
    title: "State",
    renderCell: (rowData: Model) => {
      return limitOrderStateToString(rowData.getState());
    },
  },
  submittedat: {
    title: "Submitted At",
    renderCell: (rowData: Model) => {
      return protobufTimestampToDayjs(
        rowData.getSubmittedat() ?? new Timestamp(),
      )
        .tz(timezoneToString(Timezone.SAST_TIMEZONE))
        .format("DD MMM YYYY, HH:mm:ss");
    },
  },
  cancel: {
    title: "",
    renderCell: (rowData: Model) => {
      return <CancelButton model={rowData} />;
    },
  },
};
