import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";
import { Amount } from "james/ledger";

export type CreateSubscriptionOrderBookForSmartInstrumentRequest = {
  context: Context;
  smartInstrumentID: string;
  openDate: string;
  closeDate: string;
  unitPrice: Amount;
  subscriptionAmount: Amount;
  overSubscriptionAmount: Amount;
};

export type CreateSubscriptionOrderBookForSmartInstrumentResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export const SmartInstrumentSubscriptionOrderBookCreatorServiceProviderName =
  "market-SmartInstrumentSubscriptionOrderBookCreator";

export const SmartInstrumentSubscriptionOrderBookCreator = {
  async CreateSubscriptionOrderBookForSmartInstrument(
    request: CreateSubscriptionOrderBookForSmartInstrumentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CreateSubscriptionOrderBookForSmartInstrumentResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SmartInstrumentSubscriptionOrderBookCreatorServiceProviderName}.CreateSubscriptionOrderBookForSmartInstrument`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
};
