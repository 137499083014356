import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIsMounted } from "hooks";
import { useApplicationContext } from "context/Application/Application";
import { ValidationResult } from "common/validation";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useValidatedForm } from "hooks/useForm";
import { useErrorContext } from "context/Error";
import { Token, TransactionState } from "james/ledger";
import {
  ErrSubscriptionOrderBookNotFoundCode,
  SmartInstrumentSubscriptionOrderBookCreator,
  SubscriptionOrderBookRepository,
} from "james/market";
import { TokenIdentifier } from "james/search/identifier";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import {
  MarketSubscriptionOrderBookViewReader,
  MarketSubscriptionOrderBookViewUnscopedReader,
} from "james/views/marketSubscriptionOrderBookView";
import { enqueueSnackbar } from "notistack";
import {
  FormData,
  FormUpdaterSpecsType,
  formDataValidationFunc,
  formUpdaterSpecs,
} from "./useValidatedForm";
import { networkFromFutureNetwork } from "james/ledger/Network";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import BigNumber from "bignumber.js";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";

dayjs.extend(utc);

export enum ViewMode {
  Undefined = "-",
  View = "view",
  Edit = "edit",
}

export type SubscriptionOrderBookContextType = {
  apiCallInProgress: boolean;

  viewMode: ViewMode;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  // associated data
  settlementTransactionID?: string;
  settlementTransactionState?: TransactionState | "";
  smartInstrumentUnit: Unit;

  // data for form and validation
  formData: FormData;
  formDataValidationResult: ValidationResult;
  formUpdater: FormUpdaterSpecsType;

  createBook: () => void;

  reload: () => void;
};

export const defaultContext: SubscriptionOrderBookContextType = {
  apiCallInProgress: false,

  viewMode: ViewMode.View,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  // associated data
  smartInstrumentUnit: Unit.UNDEFINED_UNIT,
  settlementTransactionID: undefined,
  settlementTransactionState: undefined,

  // data for form and validation
  formData: {
    subscriptionOrderBook: new SubscriptionOrderBook(),
  },
  formDataValidationResult: {
    valid: false,
    fieldValidations: {},
  },
  formUpdater: formUpdaterSpecs,

  createBook: () => null,

  reload: () => null,
};

const SubscriptionOrderBookContext =
  React.createContext<SubscriptionOrderBookContextType>(defaultContext);

export const useSubscriptionOrderBookContext = () =>
  useContext(SubscriptionOrderBookContext);

export const SubscriptionOrderBookContextProvider: React.FC<{
  system: boolean;
  listingQuoteAssetTokenViewModel: LedgerTokenViewModel;
  smartInstrument: SmartInstrument;
  children: React.ReactNode;
}> = ({
  children,
  smartInstrument,
  listingQuoteAssetTokenViewModel,
  system,
}) => {
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();
  const isMounted = useIsMounted();

  const [formData, formDataValidationResult, formUpdater] = useValidatedForm(
    formDataValidationFunc,
    undefined,
    formUpdaterSpecs,
    defaultContext.formData,
    new Set<string>([]),
    { skipTouchedFieldsOnValidation: true },
  );

  // ----------- subscriptionOrderBook -----------
  const [subscriptionOrderBookLoaded, setSubscriptionOrderBookLoaded] =
    useState(false);
  const [subscriptionOrderBookLoadError, setSubscriptionOrderBookLoadError] =
    useState<string | null>(null);
  const [settlementTransactionID, setSettlementTransactionID] = useState<
    undefined | string
  >(undefined);
  const [settlementTransactionState, setSettlementTransactionState] = useState<
    TransactionState | "" | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - data not yet loaded
      // - subscriptionOrderBook already loaed
      // - if there was an subscriptionOrderBook loading error
      // - component no longer mounted
      if (
        subscriptionOrderBookLoaded ||
        subscriptionOrderBookLoadError ||
        !isMounted()
      ) {
        return;
      }

      // First try and fetch an associated subscription order book.
      // If one is found then update the book in the form data to this
      // book, otherwise populate the book in the form data with a
      // new book.
      let subscriptionOrderBookForState: SubscriptionOrderBook | undefined;
      try {
        // fetch subscriptionOrderBook
        subscriptionOrderBookForState = (
          await SubscriptionOrderBookRepository.RetrieveSubscriptionOrderBook({
            context: authContext,
            identifier: TokenIdentifier(
              Token.fromFutureToken(smartInstrument.getToken()),
            ),
          })
        ).subscriptionOrderBook;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === ErrSubscriptionOrderBookNotFoundCode) {
          // if execution reaches here then the subscriptionOrderBook does not yet exist - prepare a new one
          subscriptionOrderBookForState = new SubscriptionOrderBook();
          subscriptionOrderBookForState.exchangeNetwork =
            networkFromFutureNetwork(
              smartInstrument.getToken()?.getNetwork() ??
                FutureNetwork.UNDEFINED_NETWORK,
            );
          const unitNominal = listingQuoteAssetTokenViewModel.token.newAmountOf(
            new BigNumber("1000"),
          );
          subscriptionOrderBookForState.unitPrice = unitNominal;
          subscriptionOrderBookForState.subscriptionAmount =
            unitNominal.setValue(
              unitNominal.value.multipliedBy(new BigNumber("1000")),
            );
          subscriptionOrderBookForState.overSubscriptionAmount =
            unitNominal.setValue(
              unitNominal.value.multipliedBy(new BigNumber("2000")),
            );
          const issueDate = protobufTimestampToDayjs(
            smartInstrument.getIssuedate() ?? new Timestamp(),
          );
          subscriptionOrderBookForState.settlementDate = issueDate.format();
          subscriptionOrderBookForState.closeDate = issueDate.format();
          subscriptionOrderBookForState.openDate = issueDate
            .subtract(1, "weeks")
            .format();
          subscriptionOrderBookForState.token = Token.fromFutureToken(
            smartInstrument.getToken(),
          );
        } else {
          console.error(
            "unexpected error retrieving subscription order book",
            e,
          );
          setSubscriptionOrderBookLoadError(err.message);
          return;
        }
      }

      // try and get the subscription order book view model to get the settlement transaction ID
      let settlementTransactionIDForState: undefined | string;
      let settlementTransactionStateForState: undefined | TransactionState | "";
      try {
        const model = (
          system
            ? await MarketSubscriptionOrderBookViewUnscopedReader.UnscopedReadOne(
                {
                  context: authContext,
                  criteria: Token.fromFutureToken(
                    smartInstrument.getToken(),
                  ).toFilter(),
                },
              )
            : await MarketSubscriptionOrderBookViewReader.ReadOne({
                context: authContext,
                criteria: Token.fromFutureToken(
                  smartInstrument.getToken(),
                ).toFilter(),
              })
        ).model;
        settlementTransactionIDForState = model.settlementTransactionID;
        settlementTransactionStateForState = model.settlementTransactionState;
      } catch (e) {
        // model might not exist yet - so just log a warning if an error is thrown here
        console.warn(
          "unable to get settlement transaction id - view model may not exist yet: ",
          e,
        );
      }

      if (!subscriptionOrderBookForState) {
        setSubscriptionOrderBookLoadError(
          "SubscriptionOrderBook not correctly initialised.",
        );
        return;
      }
      formUpdater.subscriptionOrderBook(subscriptionOrderBookForState);
      setSettlementTransactionID(settlementTransactionIDForState);
      setSettlementTransactionState(settlementTransactionStateForState);

      setSubscriptionOrderBookLoaded(true);
    })();
  }, [subscriptionOrderBookLoaded, subscriptionOrderBookLoadError, isMounted]);

  const clearInitialisationError = useCallback(() => {
    setSubscriptionOrderBookLoadError(null);
  }, [subscriptionOrderBookLoadError]);

  const reload = () => {
    setSubscriptionOrderBookLoaded(false);
  };

  const [
    subscriptionOrderBookCreationInProgress,
    setSubscriptionOrderBookCreationInProgress,
  ] = useState(false);
  const createBook = async () => {
    setSubscriptionOrderBookCreationInProgress(true);
    try {
      const { subscriptionOrderBook } =
        await SmartInstrumentSubscriptionOrderBookCreator.CreateSubscriptionOrderBookForSmartInstrument(
          {
            context: authContext,
            smartInstrumentID: smartInstrument.getId(),
            openDate: formData.subscriptionOrderBook.openDate,
            closeDate: formData.subscriptionOrderBook.closeDate,
            unitPrice: formData.subscriptionOrderBook.unitPrice,
            subscriptionAmount:
              formData.subscriptionOrderBook.subscriptionAmount,
            overSubscriptionAmount:
              formData.subscriptionOrderBook.overSubscriptionAmount,
          },
        );
      formUpdater.subscriptionOrderBook(subscriptionOrderBook);
    } catch (e) {
      console.error("error creating subscription order book", e);
      errorContextDefaultWarningFeedback(
        e,
        "error creating subscription order book",
      );
      setSubscriptionOrderBookCreationInProgress(false);
      reload();
      return;
    }
    enqueueSnackbar("Subscription Order Book Created", {
      variant: "success",
    });

    reload();
    setSubscriptionOrderBookCreationInProgress(false);
  };

  return (
    <SubscriptionOrderBookContext.Provider
      value={{
        apiCallInProgress: subscriptionOrderBookCreationInProgress,

        viewMode:
          formData.subscriptionOrderBook.id === ""
            ? ViewMode.Edit
            : ViewMode.View,

        initialised: subscriptionOrderBookLoaded,
        initialisationError: subscriptionOrderBookLoadError,
        clearInitialisationError,

        settlementTransactionID,
        settlementTransactionState,
        smartInstrumentUnit: smartInstrument.getUnit(),

        formData,
        formDataValidationResult,
        formUpdater,

        createBook,

        reload,
      }}
    >
      {children}
    </SubscriptionOrderBookContext.Provider>
  );
};
