import React, { useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { DataSheetSectionsTab } from "../AssetOverview";
import { DataSheetSection } from "james/marketData/DataSheetSection";
import {
  ETNRollCorporateActionsDataSheetSection,
  AssetDocumentsDataSheetSection,
  AssetInvestmentObjectiveDataSheetSection,
  AssetPerformanceDataSheetSection,
  AssetPriceHistoryDataSheetSection,
  AssetSectorAllocationsAndHoldingsDataSheetSection,
  StellarAssetPublicInfoDataSheetSection,
} from "james/marketData";
import { AssetInvestmentObjectiveDataSheetSectionRenderer } from "./AssetInvestmentObjectiveDataSheetSectionRenderer";
import { AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer } from "./AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer";
import { StellarAssetPublicInfoDataSheetSectionRenderer } from "./StellarAssetPublicInfoDataSheetSectionRenderer";
import { AssetPerformanceDataSheetDataSheetSectionRenderer } from "./AssetPerformanceDataSheetDataSheetSectionRenderer";
import { AssetPriceHistoryDataSheetSectionRenderer } from "./AssetPriceHistoryDataSheetSectionRenderer";
import { AssetDocumentsDataSheetSectionRenderer } from "./AssetDocumentsDataSheetSectionRenderer";
import { AssetCorporateActionsDataSheetSectionRenderer } from "./AssetCorporateActionsSheetSectionRenderer";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { AssetSubscriptionOverviewRenderer } from "./AssetSubscriptionOverviewRenderer";
import { AssetIndependentReviewRenderer } from "./AssetIndependentReviewRenderer";
import { AssetManagingRiskSectionRenderer } from "./AssetManagingRiskSectionRenderer";
import { AssetSubscriptionOverviewDataSheetSection } from "james/marketData/AssetSubscriptionOverviewDataSheetSection";
import { AssetIndependentReviewDataSheetSection } from "james/marketData/AssetIndependentReviewDataSheetSection";
import { AssetManagingRiskDataSheetSection } from "james/marketData/AssetManagingRiskDataSheetSection";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { AssetMediaAndResourcesSectionRenderer } from "./AssetMediaAndResourcesSectionRenderer/AssetMediaAndResourcesSectionRenderer";
import { AssetMarketingMediaDataSheetSection } from "james/marketData/AssetMarketingMedia";
import { AssetRepaymentTermsDataSheetSection } from "james/marketData/AssetRepaymentTermsDataSheetSection";
import { AssetRepaymentTermsSectionRenderer } from "./AssetRepaymentTermsSectionRenderer/AssetRepaymentTermsSectionRenderer";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { AssetType } from "james/views/marketListingView/Model";

interface AssetDataSheetsProps {
  dataSheetSections: DataSheetSection[];
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
  setExpandedState: React.Dispatch<
    React.SetStateAction<{
      [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
    }>
  >;
  expandedState: {
    [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
  };

  functions: AssetOverviewFunctions;
  isBond: boolean;
  documentsExist: boolean;
}

interface AssetOverviewFunctions {
  goToIssuer: () => void;
}

export const AssetDataSheets = ({
  dataSheetSections,
  setExpandedState,
  marketListingViewModel,
  expandedState,
  functions,
  documentsExist,
}: AssetDataSheetsProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const repaymentSectionHeading = useMemo(() => {
    switch (marketListingViewModel.assetType) {
      case AssetType.Bond:
        return "Repayment Terms";
      case AssetType.PreferenceShare:
        return "Distribution Terms";
      case AssetType.Share:
        return "Shareholder Returns";
      default:
        return "Distribution Terms";
    }
  }, [marketListingViewModel]);
  return (
    <>
      {dataSheetSections.map((section, idx) => {
        // do not render the subscription book overview if the book is settled OR cancelled
        if (
          section instanceof AssetSubscriptionOverviewDataSheetSection &&
          (
            [
              SubscriptionOrderBookState.Settled,
              SubscriptionOrderBookState.Cancelled,
            ] as ("" | SubscriptionOrderBookState | undefined)[]
          ).includes(
            marketListingViewModel?.marketSubscriptionOrderBookViewModel?.state,
          )
        ) {
          return null;
        }

        return (
          <Accordion
            key={`accordion-dataSheetSection-${idx}`}
            sx={{
              "&.MuiAccordion-root:before": {
                backgroundColor: "unset",
              },
              "&.Mui-expanded": {
                margin: "0",
              },
            }}
            onClick={() =>
              setExpandedState((prevState) => ({
                ...prevState,
                [DataSheetSectionsTab.Asset]: {
                  ...prevState[DataSheetSectionsTab.Asset],
                  [idx]: !prevState[DataSheetSectionsTab.Asset][idx],
                },
              }))
            }
            expanded={!!expandedState[DataSheetSectionsTab.Asset][idx]}
            data-link-info={JSON.stringify({
              content_interaction_id: "info-accordion",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: `tab>asset>${section.DataSheetSectionName()}`,
              content_interaction_type: InteractionType.Accordion,
              content_interaction_driver: InteractionDriver.MoreInfo,
            } as DataLinkInfoType)}
          >
            <AccordionSummary
              data-link-info={JSON.stringify({
                content_interaction_id: "info-accordion",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: `tab>asset>${section.DataSheetSectionName()}`,
                content_interaction_type: InteractionType.Accordion,
                content_interaction_driver: InteractionDriver.MoreInfo,
              } as DataLinkInfoType)}
              sx={(theme) => ({
                flexDirection: "row-reverse",
                backgroundColor: theme.palette.custom.midnight,
                padding: theme.spacing(0, 4),
              })}
              expandIcon={
                <ExpandMoreIcon
                  id={`accordion-${idx}-expandToggle-icon`}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "expand accordions",
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color={"primary"}
                />
              }
            >
              <Typography
                sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-accordion",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: `tab>asset>${section.DataSheetSectionName()}`,
                  content_interaction_type: InteractionType.Accordion,
                  content_interaction_driver: InteractionDriver.MoreInfo,
                } as DataLinkInfoType)}
                variant={"h6"}
                children={
                  section instanceof AssetRepaymentTermsDataSheetSection
                    ? repaymentSectionHeading
                    : section.DataSheetSectionName()
                }
              />
            </AccordionSummary>
            <AccordionDetails
              onClick={(e) => {
                e.stopPropagation();
              }}
              sx={(theme) => ({
                padding: {
                  sm: theme.spacing(0, 0, 0, 5),
                  xs: theme.spacing(0, 0, 0, 3),
                },
              })}
            >
              {(() => {
                if (
                  section instanceof
                    AssetSubscriptionOverviewDataSheetSection &&
                  marketListingViewModel.marketSubscriptionOrderBookViewModel
                ) {
                  return (
                    <AssetSubscriptionOverviewRenderer
                      subscriptionAmount={section.subscriptionAmount}
                      subscribedAmount={section.subscribedAmount}
                      committedAmount={
                        marketListingViewModel
                          .marketSubscriptionOrderBookViewModel.committedAmount
                      }
                    />
                  );
                } else if (
                  section instanceof AssetMarketingMediaDataSheetSection
                ) {
                  return (
                    <AssetMediaAndResourcesSectionRenderer section={section} />
                  );
                } else if (
                  section instanceof AssetRepaymentTermsDataSheetSection
                ) {
                  return (
                    <AssetRepaymentTermsSectionRenderer
                      dataSheetSection={section}
                      marketListingViewModel={marketListingViewModel}
                    />
                  );
                } else if (
                  section instanceof AssetInvestmentObjectiveDataSheetSection
                ) {
                  return (
                    <AssetInvestmentObjectiveDataSheetSectionRenderer
                      dataSheetSection={section}
                    />
                  );
                } else if (
                  section instanceof AssetIndependentReviewDataSheetSection
                ) {
                  return (
                    <AssetIndependentReviewRenderer
                      dataSheetSection={section}
                    />
                  );
                } else if (
                  section instanceof AssetManagingRiskDataSheetSection
                ) {
                  return (
                    <AssetManagingRiskSectionRenderer
                      goToIssuer={functions.goToIssuer}
                      documentsExist={documentsExist}
                    />
                  );
                } else if (
                  section instanceof
                  AssetSectorAllocationsAndHoldingsDataSheetSection
                ) {
                  return (
                    <AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer
                      dataSheetSection={section}
                      pieChartHeight={isMobile ? 140 : 188}
                    />
                  );
                } else if (
                  section instanceof AssetPerformanceDataSheetSection
                ) {
                  return (
                    <AssetPerformanceDataSheetDataSheetSectionRenderer
                      viewMode={true}
                      disabled={true}
                      dataSheetSection={section}
                    />
                  );
                } else if (
                  section instanceof AssetPriceHistoryDataSheetSection
                ) {
                  return (
                    <>
                      {marketListingViewModel && (
                        <AssetPriceHistoryDataSheetSectionRenderer
                          dataSheetSection={section}
                          marketListingViewModel={marketListingViewModel}
                        />
                      )}
                    </>
                  );
                } else if (section instanceof AssetDocumentsDataSheetSection) {
                  return (
                    <AssetDocumentsDataSheetSectionRenderer
                      dataSheetSection={section}
                    />
                  );
                } else if (
                  section instanceof StellarAssetPublicInfoDataSheetSection
                ) {
                  return (
                    <>
                      {marketListingViewModel && (
                        <StellarAssetPublicInfoDataSheetSectionRenderer
                          dataSheetSection={section}
                          marketListingViewModel={marketListingViewModel}
                        />
                      )}
                    </>
                  );
                } else if (
                  section instanceof ETNRollCorporateActionsDataSheetSection
                ) {
                  return (
                    <AssetCorporateActionsDataSheetSectionRenderer
                      dataSheetSection={section}
                    />
                  );
                }

                return null;
              })()}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
