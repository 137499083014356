import {
  Box,
  Button,
  CircularProgress,
  Typography,
  alpha,
} from "@mui/material";
import React, { useMemo } from "react";
import { useBuilderContext } from "../../../../Context";
import { DataTable, RowType } from "@mesh/common-js-react/dist/Tables";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import {
  FaceOutlined as FaceIcon,
  ErrorOutline as WarningIcon,
} from "@mui/icons-material";
import { RateReset } from "@mesh/common-js/dist/financial/rateReset_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";

export type RateResetsProps = {
  height: number;
};

export const RateResets = (props: RateResetsProps) => {
  const { simulationMode } = useBuilderContext();

  return simulationMode ? <Simulated {...props} /> : <Actual {...props} />;
};

const Simulated = (props: RateResetsProps) => {
  const { formData, simulatedRateResets } = useBuilderContext();

  const rateResetColumns: RowType<
    RateReset,
    Omit<
      RateReset.AsObject,
      "id" | "number" | "ownerid" | "auditentry" | "smartinstrumentid" | "legid"
    >
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Sequence No.",
        renderCell: (rowData: RateReset) => {
          return rowData.getSequencenumber();
        },
      },
      fixed: {
        title: "Fixed",
        renderCell: (rowData: RateReset) => {
          return rowData.getFixed() ? "True" : "False";
        },
      },
      ratesampledate: {
        title: "Sample Date",
        renderCell: (rowData: RateReset) => {
          return protobufTimestampToDayjs(
            rowData.getRatesampledate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      rateapplicationdate: {
        title: "Application Date",
        renderCell: (rowData: RateReset) => {
          return protobufTimestampToDayjs(
            rowData.getRateapplicationdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      referencerate: {
        title: "Ref. Rate",
        renderCell: (rowData: RateReset) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getReferencerate() ?? new Decimal()),
          );
        },
      },
      rate: {
        title: "Rate",
        renderCell: (rowData: RateReset) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getRate() ?? new Decimal()),
          );
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!simulatedRateResets.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Rate Resets</i> here after running{" "}
                <b>Calculate</b>
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={simulatedRateResets}
      columns={rateResetColumns}
    />
  );
};

const Actual = (props: RateResetsProps) => {
  const {
    formData,
    rateResets,
    rateResetsLoaded,
    rateResetLoadError,
    clearRateResetLoadError,
  } = useBuilderContext();

  const rateResetColumns: RowType<
    RateReset,
    Omit<
      RateReset.AsObject,
      "id" | "ownerid" | "auditentry" | "smartinstrumentid" | "legid"
    >
  > = useMemo(
    () => ({
      number: {
        title: "No.",
        renderCell: (rowData: RateReset) => {
          return rowData.getNumber();
        },
      },
      sequencenumber: {
        title: "Sequence No.",
        renderCell: (rowData: RateReset) => {
          return rowData.getSequencenumber();
        },
      },
      fixed: {
        title: "Fixed",
        renderCell: (rowData: RateReset) => {
          return rowData.getFixed() ? "True" : "False";
        },
      },
      ratesampledate: {
        title: "Sample Date",
        renderCell: (rowData: RateReset) => {
          return protobufTimestampToDayjs(
            rowData.getRatesampledate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      rateapplicationdate: {
        title: "Application Date",
        renderCell: (rowData: RateReset) => {
          return protobufTimestampToDayjs(
            rowData.getRateapplicationdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      referencerate: {
        title: "Ref. Rate",
        renderCell: (rowData: RateReset) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getReferencerate() ?? new Decimal()),
          );
        },
      },
      rate: {
        title: "Rate",
        renderCell: (rowData: RateReset) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getRate() ?? new Decimal()),
          );
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!rateResetsLoaded) {
    return (
      <Box
        sx={(theme) => ({
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          gap: theme.spacing(2),
        })}
      >
        <CircularProgress size={70} />
        <Typography variant="h5" color="textSecondary" children="Loading..." />
      </Box>
    );
  }

  if (rateResetLoadError) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <WarningIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Something Went Wrong"
          />
          <Typography variant="body2" children={rateResetLoadError} />
          <Button onClick={clearRateResetLoadError}>Try Again</Button>
        </Box>
      </Box>
    );
  }

  if (!rateResets.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Rate Resets</i> once they have been generated.
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={rateResets}
      columns={rateResetColumns}
    />
  );
};
