import { ValidationResult } from "common/validation";
import { MechanismType } from "james/market";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import dayjs from "dayjs";

export type FormData = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type FormUpdaterSpecsType = {
  subscriptionOrderBook: (
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  subscriptionOrderBook(
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: FormData,
  ) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(subscriptionOrderBook),
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${MechanismType.Subscription}-${field}`
    ] = message;
  };

  const allowFractions =
    formData.subscriptionOrderBook.fractionalisationAllowed;

  const unitPrice = formData.subscriptionOrderBook.unitPrice.value;
  const subscriptionAmount =
    formData.subscriptionOrderBook.subscriptionAmount.value;
  const overSubscriptionAmount =
    formData.subscriptionOrderBook.overSubscriptionAmount.value;

  // Less than zero is disabled on the component level
  // Validations are in place for the sake of completenes
  if (unitPrice.isLessThanOrEqualTo(0)) {
    invalid("unitPrice", "Unit price must be greater than 0");
  }

  if (subscriptionAmount.isLessThanOrEqualTo(0)) {
    invalid("subscriptionAmount", "Subscription amount must be greater than 0");
  }

  if (
    !allowFractions &&
    subscriptionAmount.mod(unitPrice).abs().isGreaterThan(0)
  ) {
    invalid(
      "subscriptionAmount",
      "Subscription amount should be multiples of the unit price",
    );
  }

  if (overSubscriptionAmount.isLessThanOrEqualTo(0)) {
    invalid(
      "overSubscriptionAmount",
      "Over Subscription amount must be greater than 0",
    );
  }

  if (
    !allowFractions &&
    overSubscriptionAmount.mod(unitPrice).abs().isGreaterThan(0)
  ) {
    invalid(
      "overSubscriptionAmount",
      "Over Subscription amount should be multiples of the unit price",
    );
  }

  if (overSubscriptionAmount.isLessThan(subscriptionAmount)) {
    invalid(
      "overSubscriptionAmount",
      "Over Subscription amount should be greater than or equal to subscription amount",
    );
    invalid(
      "overSubscriptionAmount",
      "Subscription amount should be less than or equal to the over subscription amount",
    );
  }

  // The date checks are handled by the component
  // A user can manually enter an invalid date
  // So these validations are needed
  const openDate = dayjs(formData.subscriptionOrderBook.openDate);
  const closeDate = dayjs(formData.subscriptionOrderBook.closeDate);
  const settlementDate = dayjs(formData.subscriptionOrderBook.settlementDate);

  if (openDate.startOf("day").isBefore(dayjs().endOf("day"))) {
    invalid("openDate", "Open date should be after today");
  }
  if (openDate.endOf("day").isAfter(settlementDate.startOf("day"))) {
    invalid("openDate", "Open date should be before settlement date");
  }

  if (
    closeDate.endOf("day").isAfter(settlementDate.add(1, "day").startOf("day"))
  ) {
    invalid("closeDate", "Close date should be before settlement date");
  }

  if (closeDate.startOf("day").isBefore(openDate.endOf("day"))) {
    invalid("closeDate", "Close date should be after open date");
    invalid("openDate", "Open date should be before close date");
  }

  return validationResult;
};
