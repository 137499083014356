import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLimitOrderController } from "../../../hooks/useLimitOrderController";
import { useExchangeDashboardStore } from "../../../store";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { limitOrderTypeToString } from "@mesh/common-js/dist/market/limitOrderType";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { useExchangeStore } from "views/Marketplace/components/Exchange/store";

interface LimitOrderConfirmationDialogProps extends DialogProps {
  closeDialog: () => void;
}

export const LimitOrderConfirmationDialog = ({
  closeDialog,
  ...props
}: LimitOrderConfirmationDialogProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [acceptedAgreement, setAcceptedAgreement] = React.useState(false);
  const { submitLimitOrder } = useLimitOrderController();
  const store = useExchangeDashboardStore();
  const exchangeStore = useExchangeStore();

  return (
    <Dialog
      open={props.open}
      fullScreen={smDown}
      PaperProps={{
        sx: {
          width: {
            sm: 380,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          py: 2,
          pl: 3,
          pr: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {exchangeStore.selectedMarketListing && (
            <>
              <IconViewUpload
                token={exchangeStore.selectedMarketListing?.token}
              />
              <Box>
                <Typography
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                  variant="h5"
                  id={`smallHeader-heading-typography-${exchangeStore.selectedMarketListing.assetName}`}
                  children={exchangeStore.selectedMarketListing.assetName}
                />
                <Typography
                  noWrap
                  id={`smallHeader-caption-typography-${exchangeStore.selectedMarketListing.assetName}`}
                  variant={"caption"}
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  children={exchangeStore.selectedMarketListing.token.code}
                />
              </Box>
            </>
          )}

          <IconButton
            sx={{
              ml: "auto",
            }}
            onClick={() => {
              closeDialog();
              setAcceptedAgreement(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          px: 5,
        }}
      >
        <Typography
          sx={{
            mt: 3,
            mb: 1,
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Confirm {limitOrderTypeToString(store.tradeCardState.cardOption)}{" "}
          Order
        </Typography>

        <Box>
          <Typography color="textSecondary" variant="caption">
            Price
          </Typography>
          <Amount
            amount={AmountType.fromFutureAmount(store.tradeCardState.price)}
          />
        </Box>
        <Box>
          <Typography color="textSecondary" variant="caption">
            Amount
          </Typography>
          <Amount
            amount={AmountType.fromFutureAmount(store.tradeCardState.amount)}
          />
        </Box>

        <Box>
          <Typography variant="caption">Investment Total</Typography>
          <Amount
            codeTypographyProps={{
              sx: (theme) => ({
                fontWeight: "bold",
                color: theme.palette.custom.yellow,
              }),
            }}
            valueTypographyProps={{
              sx: (theme) => ({
                fontWeight: "bold",
                color: theme.palette.custom.darkYellow,
              }),
            }}
            amount={AmountType.fromFutureAmount(
              store.tradeCardState.estimatedTotal,
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.custom.midnight,
          borderTop: `1px solid ${theme.palette.divider}`,
          px: 5,
          pb: 3,
        })}
      >
        <FormControlLabel
          value={acceptedAgreement}
          onChange={() => setAcceptedAgreement(!acceptedAgreement)}
          control={<Checkbox id="termsAndConditionsCheckBox" />}
          label={
            <Typography
              sx={{
                ml: 1,
              }}
              color="textSecondary"
              variant="caption"
            >
              I am aware that this trade is good-til-cancelled (GTC)
            </Typography>
          }
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
            gap: 2,
            mt: 2,
            "& > *": {
              height: {
                sm: 36,
                xs: 48,
              },
            },
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              closeDialog();
              setAcceptedAgreement(false);
            }}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={submitLimitOrder}
            disabled={!acceptedAgreement}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
