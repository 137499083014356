import { useExchangeDashboardStore } from "../store";
import * as StellarSDK from "stellar-sdk";
import { BigNumber } from "bignumber.js";
import { useExchangeStore } from "../../../store";
import { useStellarContext } from "context/Stellar";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";
import { OrderBook } from "pkgTemp/stellar/Orders";

export const useFetchAndStreamOrderBook = () => {
  const store = useExchangeDashboardStore();
  const { stellarOrdersViewer } = useStellarContext();
  const exchangeStore = useExchangeStore();
  const handleNewOrderBook = (orderBook: OrderBook) => {
    const newOrderBook = orderBook.aggregateOrderBook();
    newOrderBook.precision = store.orderBookState.precision;
    store.updateOrderBookState.serverConnectionStatus(
      ServerConnectionStatus.Connecting,
    );

    let highestOrder = new BigNumber(0);
    for (const ask of newOrderBook.aggregatedAsks) {
      if (ask.amount.gte(highestOrder)) {
        highestOrder = ask.amount;
      }
    }
    for (const bid of newOrderBook.aggregatedBids) {
      if (bid.amount.gte(highestOrder)) {
        highestOrder = bid.amount;
      }
    }

    if (orderBook.asks.length > 0 && orderBook.bids.length > 0) {
      const spread = orderBook.asks[orderBook.asks.length - 1].price
        .minus(orderBook.bids[0].price)
        .abs();
      store.updateOrderBookState.spread(spread);
    }

    store.updateOrderBookState.serverConnectionStatus(
      ServerConnectionStatus.Connected,
    );

    store.updateOrderBookState.highestPrice(highestOrder);
    store.updateOrderBookState.orderBookState(newOrderBook);
  };

  const handleNewRecord = (
    orderBook: StellarSDK.Horizon.ServerApi.OrderbookRecord,
  ) => {
    handleNewOrderBook(OrderBook.fromOrderBookRecord(orderBook));
  };

  // Fetch and stream order book
  const fetchAndStreamOrderBook = async () => {
    if (!exchangeStore.marketListingPair) return;
    const pair = exchangeStore.marketListingPair;

    let baseAsset = StellarSDK.Asset.native();
    let counterAsset = StellarSDK.Asset.native();

    if (
      pair.base.code !== baseAsset.code &&
      pair.base.issuer !== baseAsset.issuer
    ) {
      baseAsset = new StellarSDK.Asset(pair.base.code, pair.base.issuer);
    }
    if (
      pair.counter.code !== counterAsset.code &&
      pair.counter.issuer !== counterAsset.issuer
    ) {
      counterAsset = new StellarSDK.Asset(
        pair.counter.code,
        pair.counter.issuer,
      );
    }
    const onError = () => {
      store.updateOrderBookState.serverConnectionStatus(
        ServerConnectionStatus.Disconnected,
      );
    };

    try {
      const startTime = performance.now();
      store.updateOrderBookState.loading(true);
      const response = await stellarOrdersViewer.FetchOrderBook({
        baseAsset: baseAsset,
        counterAsset: counterAsset,
      });

      handleNewOrderBook(response.orderBook);

      const endTime = performance.now();

      store.updateOrderBookState.serverConnectionStatus(
        ServerConnectionStatus.Connected,
      );

      setTimeout(
        () => {
          store.updateOrderBookState.loading(false);
        },
        5000 - (endTime - startTime),
      );

      return await stellarOrdersViewer.StreamOrderBook({
        baseAsset: baseAsset,
        counterAsset: counterAsset,
        onUpdate: handleNewRecord,
        onError: onError,
        order: "desc",
      });
    } catch (error) {
      console.error("Error loading order book:", error);
    }
  };

  return {
    fetchAndStreamOrderBook,
  };
};
