import React from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ExchangeListings } from "./components/ExchangeListings/ExchangeListings";
import { ExchangeDashboard } from "./components/ExchangeDashboard/ExchangeDashboard";
import { useExchangeStore } from "./store";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";

export const Exchange = () => {
  const store = useExchangeStore();
  useInitialDataFetch();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Routes>
        <Route index element={<ExchangeListings exchangeStore={store} />} />
        <Route path="*" element={<ExchangeDashboard exchangeStore={store} />} />
      </Routes>
    </Box>
  );
};
