"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Criteria = void 0;
// Utility Type to keep track of multiple criteria with key/value pairs
class Criteria {
    constructor() {
        this.criteriaMap = {};
    }
    updateCriterion(key, criterion) {
        this.criteriaMap[key] = criterion;
    }
    toCriterionList() {
        return Object.entries(this.criteriaMap);
    }
}
exports.Criteria = Criteria;
