import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useMyOffersStore } from "../store";
import { CancelLimitOrderRequest } from "@mesh/common-js/dist/market/limitOrderStateController_pb";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";
import { useExchangeDashboardStore } from "../../../store";
import { useNotificationContext } from "context/Notification";
import { useSnackbar } from "notistack";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";

export const useLimitOrderController = () => {
  const { authContext } = useApplicationContext();
  const store = useMyOffersStore();
  const { market } = useAPIContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const exchangeDashStore = useExchangeDashboardStore();
  const { registerNotificationCallback } = useNotificationContext();
  const { enqueueSnackbar } = useSnackbar();

  const cancelLimitOrder = async (model: Model) => {
    let limitOrderCancelTransactionID: string;
    try {
      store.setCancelingOrder(true);
      enqueueSnackbar("Cancellation in Progress", {
        variant: "info",
      });
      limitOrderCancelTransactionID = (
        await market.limitOrderStateControllerPromiseClient.cancelLimitOrder(
          new CancelLimitOrderRequest()
            .setContext(authContext.toFuture())
            .setLimitorderid(model.getLimitorderid())
            .setSourceaccountid(
              exchangeDashStore.tradeCardState.sourceAccountID,
            ),
        )
      ).getTransactionid();
      try {
        // register callback to fire once the deposit has been submitted
        const deregister = await registerNotificationCallback(
          new TransactionNotificationChannel({
            transactionID: limitOrderCancelTransactionID,
            private: true,
          }),
          [
            TransactionSucceededNotificationTypeName,
            TransactionFailedNotificationTypeName,
            TransactionSubmissionResolutionFailedNotificationTypeName,
          ],
          (n: Notification) => {
            if (n instanceof TransactionSucceededNotification) {
              enqueueSnackbar("Success! Cancellation Complete", {
                variant: "success",
              });
            }

            if (n instanceof TransactionFailedNotification) {
              enqueueSnackbar("Error! Cancellation Failed Failed", {
                variant: "error",
              });
            }

            if (
              n instanceof TransactionSubmissionResolutionFailedNotification
            ) {
              enqueueSnackbar(
                "Warning! Something has gone wrong with the cancellation and its status is being investigated",
                { variant: "warning" },
              );
            }
            deregister();
          },
        );
      } catch (e) {
        console.error(
          "error registering for cancellation transaction notifications",
          e,
        );
        enqueueSnackbar(
          "Warning! Unable to Register for Notifications on Cancellation Transaction - Please Refresh to Monitor.",
          { variant: "warning" },
        );
      }
      store.setCancelingOrder(false);
    } catch (e) {
      store.setCancelingOrder(false);
      errorContextDefaultErrorFeedback(e);
    }
  };

  return {
    cancelLimitOrder,
  };
};
