import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { TabOption, useMyOffersStore } from "../store";
import { useErrorContext } from "context/Error";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { LimitOrderState } from "@mesh/common-js/dist/market/limitOrder_pb";
import { DataRequest } from "@mesh/common-js-react/dist/Tables";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import { newUint32ListCriterion } from "@mesh/common-js/dist/search";

export const FilterMap: Record<TabOption, number[]> = {
  [TabOption.MyOffers]: [
    LimitOrderState.SUBMISSION_IN_PROGRESS,
    LimitOrderState.CANCELLATION_IN_PROGRESS,
    LimitOrderState.OPEN,
    LimitOrderState.COMPLETE_IN_PROGRESS,
  ],
  [TabOption.History]: [
    LimitOrderState.SUBMISSION_FAILED,
    LimitOrderState.CANCELLED,
    LimitOrderState.COMPLETE,
  ],
};

export const useSearchLimitOrderView = () => {
  const store = useMyOffersStore();
  const { views } = useAPIContext();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  // TODO
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchMarketLimitOrderView = async (request: DataRequest) => {
    try {
      store.setSearchingOffers(true);
      const response =
        await views.marketLimitOrderViewModelReader.readManyModel(
          store.request
            .setContext(authContext.toFuture())
            .setCriteriaList(store.request.getCriteriaList())
            .setQuery(store.request.getQuery()),
        );

      return response;
    } catch (e) {
      store.setLoading(false);
      const err = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(err);
    }
  };

  const refresh = async () => {
    store.setRequest(
      store.request
        .setContext(authContext.toFuture())
        .setCriteriaList([
          newUint32ListCriterion("state", FilterMap[store.selectedTab]),
        ])
        .setQuery(
          (store.request.getQuery() ?? new Query())
            .setOffset(0)
            .setLimit(10)
            .setSortingList([
              new Sorting()
                .setField("number")
                .setOrder(SortingOrder.DESC_SORTING_ORDER),
            ]),
        ),
    );
  };

  return {
    searchMarketLimitOrderView,
    refresh,
  };
};
