// source: market/limitOrderStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_token_pb = require('../ledger/token_pb.js');
goog.object.extend(proto, ledger_token_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var market_limitOrder_pb = require('../market/limitOrder_pb.js');
goog.object.extend(proto, market_limitOrder_pb);
goog.exportSymbol('proto.market.CancelLimitOrderRequest', null, global);
goog.exportSymbol('proto.market.CancelLimitOrderResponse', null, global);
goog.exportSymbol('proto.market.CompleteLimitOrderRequest', null, global);
goog.exportSymbol('proto.market.CompleteLimitOrderResponse', null, global);
goog.exportSymbol('proto.market.Effect', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderCancelledRequest', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderCancelledResponse', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderCompleteRequest', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderCompleteResponse', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderOpenRequest', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderOpenResponse', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderSubmissionFailedRequest', null, global);
goog.exportSymbol('proto.market.MarkLimitOrderSubmissionFailedResponse', null, global);
goog.exportSymbol('proto.market.ResolveIDStateRequest', null, global);
goog.exportSymbol('proto.market.ResolveIDStateResponse', null, global);
goog.exportSymbol('proto.market.ResolveStateFromOfferRequest', null, global);
goog.exportSymbol('proto.market.ResolveStateFromOfferResponse', null, global);
goog.exportSymbol('proto.market.SubmitLimitOrderRequest', null, global);
goog.exportSymbol('proto.market.SubmitLimitOrderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.SubmitLimitOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.SubmitLimitOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.SubmitLimitOrderRequest.displayName = 'proto.market.SubmitLimitOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.SubmitLimitOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.SubmitLimitOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.SubmitLimitOrderResponse.displayName = 'proto.market.SubmitLimitOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderCompleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderCompleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderCompleteRequest.displayName = 'proto.market.MarkLimitOrderCompleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderCompleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderCompleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderCompleteResponse.displayName = 'proto.market.MarkLimitOrderCompleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderOpenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderOpenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderOpenRequest.displayName = 'proto.market.MarkLimitOrderOpenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderOpenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderOpenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderOpenResponse.displayName = 'proto.market.MarkLimitOrderOpenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.CompleteLimitOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.CompleteLimitOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.CompleteLimitOrderRequest.displayName = 'proto.market.CompleteLimitOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.CompleteLimitOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.CompleteLimitOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.CompleteLimitOrderResponse.displayName = 'proto.market.CompleteLimitOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.CancelLimitOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.CancelLimitOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.CancelLimitOrderRequest.displayName = 'proto.market.CancelLimitOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.CancelLimitOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.CancelLimitOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.CancelLimitOrderResponse.displayName = 'proto.market.CancelLimitOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderCancelledRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderCancelledRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderCancelledRequest.displayName = 'proto.market.MarkLimitOrderCancelledRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderCancelledResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderCancelledResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderCancelledResponse.displayName = 'proto.market.MarkLimitOrderCancelledResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderSubmissionFailedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderSubmissionFailedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderSubmissionFailedRequest.displayName = 'proto.market.MarkLimitOrderSubmissionFailedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkLimitOrderSubmissionFailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkLimitOrderSubmissionFailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkLimitOrderSubmissionFailedResponse.displayName = 'proto.market.MarkLimitOrderSubmissionFailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ResolveIDStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.ResolveIDStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ResolveIDStateRequest.displayName = 'proto.market.ResolveIDStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ResolveIDStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.ResolveIDStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ResolveIDStateResponse.displayName = 'proto.market.ResolveIDStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ResolveStateFromOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.ResolveStateFromOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ResolveStateFromOfferRequest.displayName = 'proto.market.ResolveStateFromOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ResolveStateFromOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.ResolveStateFromOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ResolveStateFromOfferResponse.displayName = 'proto.market.ResolveStateFromOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.Effect = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.Effect, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.Effect.displayName = 'proto.market.Effect';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.SubmitLimitOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.SubmitLimitOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.SubmitLimitOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.SubmitLimitOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
listingid: jspb.Message.getFieldWithDefault(msg, 2, ""),
type: jspb.Message.getFieldWithDefault(msg, 3, 0),
price: (f = msg.getPrice()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
amount: (f = msg.getAmount()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
sourceaccountid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.SubmitLimitOrderRequest}
 */
proto.market.SubmitLimitOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.SubmitLimitOrderRequest;
  return proto.market.SubmitLimitOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.SubmitLimitOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.SubmitLimitOrderRequest}
 */
proto.market.SubmitLimitOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingid(value);
      break;
    case 3:
      var value = /** @type {!proto.market.LimitOrderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceaccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.SubmitLimitOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.SubmitLimitOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.SubmitLimitOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.SubmitLimitOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getListingid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getSourceaccountid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.SubmitLimitOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
*/
proto.market.SubmitLimitOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
 */
proto.market.SubmitLimitOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.SubmitLimitOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string listingID = 2;
 * @return {string}
 */
proto.market.SubmitLimitOrderRequest.prototype.getListingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
 */
proto.market.SubmitLimitOrderRequest.prototype.setListingid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LimitOrderType type = 3;
 * @return {!proto.market.LimitOrderType}
 */
proto.market.SubmitLimitOrderRequest.prototype.getType = function() {
  return /** @type {!proto.market.LimitOrderType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.market.LimitOrderType} value
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
 */
proto.market.SubmitLimitOrderRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ledger.FutureAmount price = 4;
 * @return {?proto.ledger.FutureAmount}
 */
proto.market.SubmitLimitOrderRequest.prototype.getPrice = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 4));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
*/
proto.market.SubmitLimitOrderRequest.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
 */
proto.market.SubmitLimitOrderRequest.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.SubmitLimitOrderRequest.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ledger.FutureAmount amount = 5;
 * @return {?proto.ledger.FutureAmount}
 */
proto.market.SubmitLimitOrderRequest.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 5));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
*/
proto.market.SubmitLimitOrderRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
 */
proto.market.SubmitLimitOrderRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.SubmitLimitOrderRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string sourceAccountID = 6;
 * @return {string}
 */
proto.market.SubmitLimitOrderRequest.prototype.getSourceaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.SubmitLimitOrderRequest} returns this
 */
proto.market.SubmitLimitOrderRequest.prototype.setSourceaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.SubmitLimitOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.SubmitLimitOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.SubmitLimitOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.SubmitLimitOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f),
transactionid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.SubmitLimitOrderResponse}
 */
proto.market.SubmitLimitOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.SubmitLimitOrderResponse;
  return proto.market.SubmitLimitOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.SubmitLimitOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.SubmitLimitOrderResponse}
 */
proto.market.SubmitLimitOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.SubmitLimitOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.SubmitLimitOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.SubmitLimitOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.SubmitLimitOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.SubmitLimitOrderResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.SubmitLimitOrderResponse} returns this
*/
proto.market.SubmitLimitOrderResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.SubmitLimitOrderResponse} returns this
 */
proto.market.SubmitLimitOrderResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.SubmitLimitOrderResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string transactionID = 2;
 * @return {string}
 */
proto.market.SubmitLimitOrderResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.SubmitLimitOrderResponse} returns this
 */
proto.market.SubmitLimitOrderResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderCompleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderCompleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCompleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderCompleteRequest}
 */
proto.market.MarkLimitOrderCompleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderCompleteRequest;
  return proto.market.MarkLimitOrderCompleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderCompleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderCompleteRequest}
 */
proto.market.MarkLimitOrderCompleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderCompleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderCompleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCompleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.MarkLimitOrderCompleteRequest} returns this
*/
proto.market.MarkLimitOrderCompleteRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderCompleteRequest} returns this
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LimitOrder limitOrder = 2;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 2));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderCompleteRequest} returns this
*/
proto.market.MarkLimitOrderCompleteRequest.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderCompleteRequest} returns this
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderCompleteRequest.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderCompleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderCompleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderCompleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCompleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderCompleteResponse}
 */
proto.market.MarkLimitOrderCompleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderCompleteResponse;
  return proto.market.MarkLimitOrderCompleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderCompleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderCompleteResponse}
 */
proto.market.MarkLimitOrderCompleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderCompleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderCompleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderCompleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCompleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderCompleteResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderCompleteResponse} returns this
*/
proto.market.MarkLimitOrderCompleteResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderCompleteResponse} returns this
 */
proto.market.MarkLimitOrderCompleteResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderCompleteResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderOpenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderOpenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderOpenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderOpenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderOpenRequest}
 */
proto.market.MarkLimitOrderOpenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderOpenRequest;
  return proto.market.MarkLimitOrderOpenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderOpenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderOpenRequest}
 */
proto.market.MarkLimitOrderOpenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderOpenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderOpenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderOpenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderOpenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.MarkLimitOrderOpenRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.MarkLimitOrderOpenRequest} returns this
*/
proto.market.MarkLimitOrderOpenRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderOpenRequest} returns this
 */
proto.market.MarkLimitOrderOpenRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderOpenRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LimitOrder limitOrder = 2;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderOpenRequest.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 2));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderOpenRequest} returns this
*/
proto.market.MarkLimitOrderOpenRequest.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderOpenRequest} returns this
 */
proto.market.MarkLimitOrderOpenRequest.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderOpenRequest.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderOpenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderOpenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderOpenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderOpenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderOpenResponse}
 */
proto.market.MarkLimitOrderOpenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderOpenResponse;
  return proto.market.MarkLimitOrderOpenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderOpenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderOpenResponse}
 */
proto.market.MarkLimitOrderOpenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderOpenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderOpenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderOpenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderOpenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderOpenResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderOpenResponse} returns this
*/
proto.market.MarkLimitOrderOpenResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderOpenResponse} returns this
 */
proto.market.MarkLimitOrderOpenResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderOpenResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.CompleteLimitOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.CompleteLimitOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.CompleteLimitOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CompleteLimitOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.CompleteLimitOrderRequest}
 */
proto.market.CompleteLimitOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.CompleteLimitOrderRequest;
  return proto.market.CompleteLimitOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.CompleteLimitOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.CompleteLimitOrderRequest}
 */
proto.market.CompleteLimitOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.CompleteLimitOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.CompleteLimitOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.CompleteLimitOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CompleteLimitOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.CompleteLimitOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.CompleteLimitOrderRequest} returns this
*/
proto.market.CompleteLimitOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.CompleteLimitOrderRequest} returns this
 */
proto.market.CompleteLimitOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.CompleteLimitOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LimitOrder limitOrder = 2;
 * @return {?proto.market.LimitOrder}
 */
proto.market.CompleteLimitOrderRequest.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 2));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.CompleteLimitOrderRequest} returns this
*/
proto.market.CompleteLimitOrderRequest.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.CompleteLimitOrderRequest} returns this
 */
proto.market.CompleteLimitOrderRequest.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.CompleteLimitOrderRequest.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.CompleteLimitOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.CompleteLimitOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.CompleteLimitOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CompleteLimitOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.CompleteLimitOrderResponse}
 */
proto.market.CompleteLimitOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.CompleteLimitOrderResponse;
  return proto.market.CompleteLimitOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.CompleteLimitOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.CompleteLimitOrderResponse}
 */
proto.market.CompleteLimitOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.CompleteLimitOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.CompleteLimitOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.CompleteLimitOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CompleteLimitOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.CompleteLimitOrderResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.CompleteLimitOrderResponse} returns this
*/
proto.market.CompleteLimitOrderResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.CompleteLimitOrderResponse} returns this
 */
proto.market.CompleteLimitOrderResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.CompleteLimitOrderResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.CancelLimitOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.CancelLimitOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.CancelLimitOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CancelLimitOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
sourceaccountid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.CancelLimitOrderRequest}
 */
proto.market.CancelLimitOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.CancelLimitOrderRequest;
  return proto.market.CancelLimitOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.CancelLimitOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.CancelLimitOrderRequest}
 */
proto.market.CancelLimitOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitorderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceaccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.CancelLimitOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.CancelLimitOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.CancelLimitOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CancelLimitOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceaccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.CancelLimitOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.CancelLimitOrderRequest} returns this
*/
proto.market.CancelLimitOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.CancelLimitOrderRequest} returns this
 */
proto.market.CancelLimitOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.CancelLimitOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string limitOrderID = 2;
 * @return {string}
 */
proto.market.CancelLimitOrderRequest.prototype.getLimitorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.CancelLimitOrderRequest} returns this
 */
proto.market.CancelLimitOrderRequest.prototype.setLimitorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sourceAccountID = 3;
 * @return {string}
 */
proto.market.CancelLimitOrderRequest.prototype.getSourceaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.CancelLimitOrderRequest} returns this
 */
proto.market.CancelLimitOrderRequest.prototype.setSourceaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.CancelLimitOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.CancelLimitOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.CancelLimitOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CancelLimitOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f),
transactionid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.CancelLimitOrderResponse}
 */
proto.market.CancelLimitOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.CancelLimitOrderResponse;
  return proto.market.CancelLimitOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.CancelLimitOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.CancelLimitOrderResponse}
 */
proto.market.CancelLimitOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.CancelLimitOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.CancelLimitOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.CancelLimitOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.CancelLimitOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.CancelLimitOrderResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.CancelLimitOrderResponse} returns this
*/
proto.market.CancelLimitOrderResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.CancelLimitOrderResponse} returns this
 */
proto.market.CancelLimitOrderResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.CancelLimitOrderResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string transactionID = 2;
 * @return {string}
 */
proto.market.CancelLimitOrderResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.CancelLimitOrderResponse} returns this
 */
proto.market.CancelLimitOrderResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderCancelledRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderCancelledRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCancelledRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderCancelledRequest}
 */
proto.market.MarkLimitOrderCancelledRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderCancelledRequest;
  return proto.market.MarkLimitOrderCancelledRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderCancelledRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderCancelledRequest}
 */
proto.market.MarkLimitOrderCancelledRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderCancelledRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderCancelledRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCancelledRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.MarkLimitOrderCancelledRequest} returns this
*/
proto.market.MarkLimitOrderCancelledRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderCancelledRequest} returns this
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LimitOrder limitOrder = 2;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 2));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderCancelledRequest} returns this
*/
proto.market.MarkLimitOrderCancelledRequest.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderCancelledRequest} returns this
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderCancelledRequest.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderCancelledResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderCancelledResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderCancelledResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCancelledResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderCancelledResponse}
 */
proto.market.MarkLimitOrderCancelledResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderCancelledResponse;
  return proto.market.MarkLimitOrderCancelledResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderCancelledResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderCancelledResponse}
 */
proto.market.MarkLimitOrderCancelledResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderCancelledResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderCancelledResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderCancelledResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderCancelledResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderCancelledResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderCancelledResponse} returns this
*/
proto.market.MarkLimitOrderCancelledResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderCancelledResponse} returns this
 */
proto.market.MarkLimitOrderCancelledResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderCancelledResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderSubmissionFailedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderSubmissionFailedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedRequest}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderSubmissionFailedRequest;
  return proto.market.MarkLimitOrderSubmissionFailedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderSubmissionFailedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedRequest}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderSubmissionFailedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderSubmissionFailedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.MarkLimitOrderSubmissionFailedRequest} returns this
*/
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedRequest} returns this
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LimitOrder limitOrder = 2;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 2));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderSubmissionFailedRequest} returns this
*/
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedRequest} returns this
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderSubmissionFailedRequest.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkLimitOrderSubmissionFailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkLimitOrderSubmissionFailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedResponse}
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkLimitOrderSubmissionFailedResponse;
  return proto.market.MarkLimitOrderSubmissionFailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkLimitOrderSubmissionFailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedResponse}
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkLimitOrderSubmissionFailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkLimitOrderSubmissionFailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.MarkLimitOrderSubmissionFailedResponse} returns this
*/
proto.market.MarkLimitOrderSubmissionFailedResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkLimitOrderSubmissionFailedResponse} returns this
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkLimitOrderSubmissionFailedResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ResolveIDStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ResolveIDStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ResolveIDStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveIDStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
limitorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
manualinvocation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ResolveIDStateRequest}
 */
proto.market.ResolveIDStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ResolveIDStateRequest;
  return proto.market.ResolveIDStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ResolveIDStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ResolveIDStateRequest}
 */
proto.market.ResolveIDStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitorderid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualinvocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ResolveIDStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ResolveIDStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ResolveIDStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveIDStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getLimitorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManualinvocation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.ResolveIDStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.ResolveIDStateRequest} returns this
*/
proto.market.ResolveIDStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ResolveIDStateRequest} returns this
 */
proto.market.ResolveIDStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ResolveIDStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string limitOrderID = 2;
 * @return {string}
 */
proto.market.ResolveIDStateRequest.prototype.getLimitorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.ResolveIDStateRequest} returns this
 */
proto.market.ResolveIDStateRequest.prototype.setLimitorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool manualInvocation = 3;
 * @return {boolean}
 */
proto.market.ResolveIDStateRequest.prototype.getManualinvocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.market.ResolveIDStateRequest} returns this
 */
proto.market.ResolveIDStateRequest.prototype.setManualinvocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ResolveIDStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ResolveIDStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ResolveIDStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveIDStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ResolveIDStateResponse}
 */
proto.market.ResolveIDStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ResolveIDStateResponse;
  return proto.market.ResolveIDStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ResolveIDStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ResolveIDStateResponse}
 */
proto.market.ResolveIDStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ResolveIDStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ResolveIDStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ResolveIDStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveIDStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.ResolveIDStateResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.ResolveIDStateResponse} returns this
*/
proto.market.ResolveIDStateResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ResolveIDStateResponse} returns this
 */
proto.market.ResolveIDStateResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ResolveIDStateResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ResolveStateFromOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ResolveStateFromOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ResolveStateFromOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveStateFromOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
effect: (f = msg.getEffect()) && proto.market.Effect.toObject(includeInstance, f),
manualinvocation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ResolveStateFromOfferRequest}
 */
proto.market.ResolveStateFromOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ResolveStateFromOfferRequest;
  return proto.market.ResolveStateFromOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ResolveStateFromOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ResolveStateFromOfferRequest}
 */
proto.market.ResolveStateFromOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new proto.market.Effect;
      reader.readMessage(value,proto.market.Effect.deserializeBinaryFromReader);
      msg.setEffect(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualinvocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ResolveStateFromOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ResolveStateFromOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ResolveStateFromOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveStateFromOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getEffect();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.market.Effect.serializeBinaryToWriter
    );
  }
  f = message.getManualinvocation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.ResolveStateFromOfferRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.ResolveStateFromOfferRequest} returns this
*/
proto.market.ResolveStateFromOfferRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ResolveStateFromOfferRequest} returns this
 */
proto.market.ResolveStateFromOfferRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ResolveStateFromOfferRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Effect effect = 2;
 * @return {?proto.market.Effect}
 */
proto.market.ResolveStateFromOfferRequest.prototype.getEffect = function() {
  return /** @type{?proto.market.Effect} */ (
    jspb.Message.getWrapperField(this, proto.market.Effect, 2));
};


/**
 * @param {?proto.market.Effect|undefined} value
 * @return {!proto.market.ResolveStateFromOfferRequest} returns this
*/
proto.market.ResolveStateFromOfferRequest.prototype.setEffect = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ResolveStateFromOfferRequest} returns this
 */
proto.market.ResolveStateFromOfferRequest.prototype.clearEffect = function() {
  return this.setEffect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ResolveStateFromOfferRequest.prototype.hasEffect = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool manualInvocation = 3;
 * @return {boolean}
 */
proto.market.ResolveStateFromOfferRequest.prototype.getManualinvocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.market.ResolveStateFromOfferRequest} returns this
 */
proto.market.ResolveStateFromOfferRequest.prototype.setManualinvocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ResolveStateFromOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ResolveStateFromOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ResolveStateFromOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveStateFromOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
limitorder: (f = msg.getLimitorder()) && market_limitOrder_pb.LimitOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ResolveStateFromOfferResponse}
 */
proto.market.ResolveStateFromOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ResolveStateFromOfferResponse;
  return proto.market.ResolveStateFromOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ResolveStateFromOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ResolveStateFromOfferResponse}
 */
proto.market.ResolveStateFromOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_limitOrder_pb.LimitOrder;
      reader.readMessage(value,market_limitOrder_pb.LimitOrder.deserializeBinaryFromReader);
      msg.setLimitorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ResolveStateFromOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ResolveStateFromOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ResolveStateFromOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ResolveStateFromOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_limitOrder_pb.LimitOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional LimitOrder limitOrder = 1;
 * @return {?proto.market.LimitOrder}
 */
proto.market.ResolveStateFromOfferResponse.prototype.getLimitorder = function() {
  return /** @type{?proto.market.LimitOrder} */ (
    jspb.Message.getWrapperField(this, market_limitOrder_pb.LimitOrder, 1));
};


/**
 * @param {?proto.market.LimitOrder|undefined} value
 * @return {!proto.market.ResolveStateFromOfferResponse} returns this
*/
proto.market.ResolveStateFromOfferResponse.prototype.setLimitorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ResolveStateFromOfferResponse} returns this
 */
proto.market.ResolveStateFromOfferResponse.prototype.clearLimitorder = function() {
  return this.setLimitorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ResolveStateFromOfferResponse.prototype.hasLimitorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.Effect.prototype.toObject = function(opt_includeInstance) {
  return proto.market.Effect.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.Effect} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.Effect.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
type: jspb.Message.getFieldWithDefault(msg, 2, ""),
buyer: jspb.Message.getFieldWithDefault(msg, 3, ""),
offerid: jspb.Message.getFieldWithDefault(msg, 4, 0),
boughtamount: (f = msg.getBoughtamount()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
seller: jspb.Message.getFieldWithDefault(msg, 6, ""),
soldamount: (f = msg.getSoldamount()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.Effect}
 */
proto.market.Effect.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.Effect;
  return proto.market.Effect.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.Effect} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.Effect}
 */
proto.market.Effect.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyer(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferid(value);
      break;
    case 5:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setBoughtamount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeller(value);
      break;
    case 7:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setSoldamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.Effect.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.Effect.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.Effect} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.Effect.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBuyer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBoughtamount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSoldamount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.market.Effect.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.market.Effect.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string buyer = 3;
 * @return {string}
 */
proto.market.Effect.prototype.getBuyer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.setBuyer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 offerID = 4;
 * @return {number}
 */
proto.market.Effect.prototype.getOfferid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.setOfferid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ledger.FutureAmount boughtAmount = 5;
 * @return {?proto.ledger.FutureAmount}
 */
proto.market.Effect.prototype.getBoughtamount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 5));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.market.Effect} returns this
*/
proto.market.Effect.prototype.setBoughtamount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.clearBoughtamount = function() {
  return this.setBoughtamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.Effect.prototype.hasBoughtamount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string seller = 6;
 * @return {string}
 */
proto.market.Effect.prototype.getSeller = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.setSeller = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ledger.FutureAmount soldAmount = 7;
 * @return {?proto.ledger.FutureAmount}
 */
proto.market.Effect.prototype.getSoldamount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 7));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.market.Effect} returns this
*/
proto.market.Effect.prototype.setSoldamount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.Effect} returns this
 */
proto.market.Effect.prototype.clearSoldamount = function() {
  return this.setSoldamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.Effect.prototype.hasSoldamount = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.market);
