import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ParticipantSection } from "./components/ParticipantSection";
import { UnderwritersSection } from "./components/UnderwritersSection";
import { AssetInspector } from "james/ledger/AssetInspector";
import { Token } from "james/ledger";
import { AssetParticipants } from "james/ledger/AssetParticipants";
import { ParticipantsLoader } from "./components/ParticipantsLoader";
import { Model as MarketListingViewModel } from "james/views/marketListingView/Model";
import { PublicModel as PublicMarketListingViewModel } from "james/views/marketListingView";
import cx from "classnames";
import { ExpandMore } from "@mui/icons-material";
import { GuarantorSection } from "./components/GuarantorSection";

interface ParticipantsProps {
  token: Token;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

export const Participants = ({
  token,
  marketListingViewModel,
}: ParticipantsProps) => {
  const [participants, setParticipants] = useState<AssetParticipants>();
  const [loading, setLoading] = useState(false);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    setTimeout(async () => {
      setLoading(true);
      try {
        const getAssetParticipantsResponse =
          await AssetInspector.GetAssetParticipants({
            token: token,
          });

        setParticipants(getAssetParticipantsResponse.participants);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    });
  }, [token]);
  const numberOfLinkedParticipants =
    participants?.linkedParticipants?.otherLinkedParticipants?.length ?? 0;
  const hasOddNumberOfLinkedParticipants = numberOfLinkedParticipants % 2 !== 0;

  return (
    <>
      {participants && !loading ? (
        <Box>
          <Box
            className={cx({
              "no-underwriters": [
                participants.instrumentParticipants.underwriters.length > 0,
              ],
            })}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              "&.no-underwriters": {
                height: "100%",
                bottom: 0,
              },
            })}
          >
            <ParticipantSection
              logoUrl={
                participants.instrumentParticipants.issuer.logoUrl ??
                marketListingViewModel.issuerLogo?.url ??
                ""
              }
              participantProfile={participants.instrumentParticipants.issuer}
              issuerSection={true}
            />
            {(participants.instrumentParticipants.underwriters.length > 0 ||
              participants.instrumentParticipants.guarantor) && (
              <Divider sx={{ mx: { sm: 5, xs: 3 } }} />
            )}
            <UnderwritersSection
              underwriters={participants.instrumentParticipants.underwriters}
            />
            {participants.instrumentParticipants.guarantor && (
              <GuarantorSection
                guarantor={participants.instrumentParticipants.guarantor}
              />
            )}
            {(participants.instrumentParticipants.underwriters.length > 0 ||
              participants.instrumentParticipants.guarantor) && (
              <Box sx={{ pb: 5 }} />
            )}
          </Box>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.grape,
              display: "flex",
              flexWrap: "wrap",
              // gap: 2, // Adjust the space between items
            })}
          >
            {participants.instrumentParticipants.externalParticipants.map(
              (participant, index) => (
                <Box
                  key={index}
                  sx={{
                    flexBasis: {
                      xs: "100%", // On mobile, all items take full width
                      sm:
                        index === 0 &&
                        participants.instrumentParticipants.externalParticipants
                          .length %
                          2 !==
                          0
                          ? "100%" // On larger screens, if odd number, first item takes full width
                          : "calc(50%)", // Otherwise, half width for the rest
                    },
                    boxSizing: "border-box",
                  }}
                >
                  <ParticipantSection
                    logoUrl={participant.logoUrl}
                    participantProfile={participant}
                    issuerSection={
                      !smDown &&
                      index === 0 &&
                      participants.instrumentParticipants.externalParticipants
                        .length %
                        2 !==
                        0
                    }
                    rightAlignSocialMedia={true}
                  />
                  {smDown && <Divider sx={{ mx: 3 }} />}
                </Box>
              ),
            )}
          </Box>
          {participants.linkedParticipants && (
            <Accordion disableGutters>
              <AccordionSummary
                sx={(theme) => ({
                  flexDirection: "row-reverse",
                  backgroundColor: theme.palette.custom.midnight,
                  padding: theme.spacing(0, 4),
                })}
                expandIcon={
                  <ExpandMore
                    id={`accordion-expandToggle-icon`}
                    color={"primary"}
                  />
                }
              >
                <Typography
                  sx={{ pl: 2 }}
                  variant={"h6"}
                  children={"Underlying Asset"}
                />
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Box
                  className={cx({})}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                  })}
                >
                  {participants.linkedParticipants?.issuer && (
                    <ParticipantSection
                      logoUrl={
                        marketListingViewModel.issuerLogo?.url ??
                        participants.linkedParticipants?.issuer.logoUrl ??
                        ""
                      }
                      participantProfile={
                        participants.linkedParticipants.issuer
                      }
                      issuerSection={true}
                      rightAlignSocialMedia={true}
                    />
                  )}
                </Box>
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.palette.custom.grape,
                    display: "flex",
                    flexWrap: "wrap",
                    //gap: 2, // Adjust the space between items
                  })}
                >
                  {participants.linkedParticipants?.otherLinkedParticipants.map(
                    (participant, index) => (
                      <Box
                        key={index}
                        sx={{
                          flexBasis: {
                            xs: "100%", // On mobile, all items take full width
                            sm:
                              index === 0 && hasOddNumberOfLinkedParticipants
                                ? "100%" // On larger screens, if odd number, first item takes full width
                                : "calc(50%)", // Otherwise, half width for the rest
                          },
                          boxSizing: "border-box",
                        }}
                      >
                        <ParticipantSection
                          logoUrl={participant.logoUrl}
                          participantProfile={participant}
                          issuerSection={
                            !smDown &&
                            index === 0 &&
                            hasOddNumberOfLinkedParticipants
                          }
                          rightAlignSocialMedia={true}
                        />
                        {smDown && <Divider sx={{ mx: 3 }} />}
                      </Box>
                    ),
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      ) : (
        <ParticipantsLoader />
      )}
    </>
  );
};
