import { Amount, Token } from "james/ledger";
import { ServerClient } from "./ClientServer";
import { Asset, Horizon } from "stellar-sdk";
import { BigNumber } from "bignumber.js";
import { StellarNetwork } from "james/stellar";

export interface StellarTrade {
  price: Amount;
  baseAmount: Amount;
  counterAmount: Amount;
  id: string;
  ledgerCloseTime: string;
  tradeType: Horizon.ServerApi.TradeType;
  type: "Buy" | "Sell";
}

export type FetchTradesForPairRequest = {
  baseAsset: Asset;
  counterAsset: Asset;
  cursor: string;
  order: "desc" | "asc";
  limit?: number;
};

export type FetchTradesForPairResponse = {
  trades: StellarTrade[];
};

export type NewStellarTradesViewer = {
  client: ServerClient;
};

export type StreamTradesRequest = {
  baseAsset: Asset;
  counterAsset: Asset;
  onTrades: (trades: Horizon.ServerApi.TradeRecord[]) => void;
  onError: () => void;
  limit?: number;
  order?: "desc" | "asc";
};

export class StellarTradesViewer {
  private readonly stellarClient: ServerClient;

  constructor(props: NewStellarTradesViewer) {
    this.stellarClient = props.client;
  }

  async FetchTradesForPair(
    request: FetchTradesForPairRequest,
  ): Promise<FetchTradesForPairResponse> {
    try {
      const response = await this.stellarClient.tradesForPair(
        request.baseAsset,
        request.counterAsset,
        request.limit,
        request.order,
      );

      return {
        trades: response.records.map((tr) => ({
          type: tr.base_is_seller ? "Sell" : "Buy",
          tradeType: tr.trade_type,
          id: tr.id,
          ledgerCloseTime: tr.ledger_close_time,
          counterAmount: new Amount({
            value: new BigNumber(tr.counter_amount),
            token: new Token({
              code: request.counterAsset.code,
              issuer: request.counterAsset.issuer,
              network: StellarNetwork.TestSDFNetwork,
            }),
          }),
          baseAmount: new Amount({
            value: new BigNumber(tr.base_amount),
            token: new Token({
              code: request.baseAsset.code,
              issuer: request.baseAsset.issuer,
              network: StellarNetwork.TestSDFNetwork,
            }),
          }),
          price: new Amount({
            value: new BigNumber(tr.price?.n ?? "").dividedBy(
              tr.price?.d ?? "",
            ),
            token: new Token({
              code: request.counterAsset.code,
              issuer: request.counterAsset.issuer,
              network: StellarNetwork.TestSDFNetwork,
            }),
          }),
        })),
      };
    } catch (e) {
      console.error(e);
      throw new Error(`FetchTradesForPair: ${e}`);
    }
  }

  async StreamTradesForPair(request: StreamTradesRequest) {
    try {
      return this.stellarClient.streamTradesForPair(
        request.baseAsset,
        request.counterAsset,
        request.onTrades,
        request.onError,
        request.limit,
        request.order,
      );
    } catch (e) {
      console.error(e);
      throw new Error(`FetchTradesForPair: ${e}`);
    }
  }
}
