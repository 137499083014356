import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { LimitOrderType } from "@mesh/common-js/dist/market/limitOrder_pb";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import { BigNumber } from "bignumber.js";
import { FormValidationType, validationFunc } from ".";
import { TradeCardProps } from "../components/TradeCard/TradeCard";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";

export const TradeCardValidations: FormValidationType<TradeCardProps> = {
  estimatedTotal: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  amount: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  price: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  cardOption: LimitOrderType.BUY,
  amountFocused: false,
  loading: false,
  sourceAccountID: "",
  valid: true,
  fieldValidations: {
    estimatedTotal: (form) => {
      let error = "";
      let valid = true;

      const estimatedTotal = decimalToBigNumber(
        form.estimatedTotal.getValue() ?? new Decimal(),
      );
      if (
        !form.balance ||
        estimatedTotal.gt(
          form.balance[form.cardOption].availableBalance().value,
        )
      ) {
        error = "Insufficient Balance";
        valid = false;
      }

      if (
        estimatedTotal.lt(form.quoteParemeter?.minimumDealSize.value ?? 0.02)
      ) {
        error = `Minimum deal size: ${form.quoteParemeter?.minimumDealSize.value.toFormat()} ${form.quoteParemeter?.quoteToken.code}`;
        valid = false;
      }

      if (estimatedTotal.gt(form.quoteParemeter?.maximumDealSize.value ?? 1)) {
        error = `Max deal size: ${form.quoteParemeter?.maximumDealSize.value.toFormat()} ${form.quoteParemeter?.quoteToken.code}`;
        valid = false;
      }

      return {
        error,
        valid,
      };
    },
    price: (form) => {
      let error = "";
      let valid = true;

      if (!decimalToBigNumber(form.price.getValue() ?? new Decimal()).gt(0)) {
        error = "Price should be greater than 0";
        valid = false;
      }

      return {
        error,
        valid,
      };
    },
    amount: (form) => {
      let error = "";
      let valid = true;

      if (!decimalToBigNumber(form.amount.getValue() ?? new Decimal()).gt(0)) {
        error = "Amount should be greater than 0";
        valid = false;
      }

      return {
        error,
        valid,
      };
    },
  },
  fieldErrors: {},
  isValid: <T>(
    form: T,
    fieldValidations: Partial<Record<keyof T, validationFunc<T>>>,
  ) => {
    const validations: Record<string, string> = {};
    let valid = true;
    Object.keys(fieldValidations).forEach((key) => {
      const r = fieldValidations[key as keyof T]?.(form);
      if (r && !r.valid) {
        validations[key] = r.error;
        valid = false;
      }
    });

    return {
      fieldErrors: validations,
      valid: valid,
    };
  },
};
