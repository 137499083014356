import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Model as LedgerTokenViewModel,
  Reader as LedgerTokenViewReader,
} from "../../../../../james/views/ledgerTokenView";
import { useIsMounted } from "hooks";
import { useApplicationContext } from "context/Application/Application";
import {
  TextListCriterion,
  TextNINListCriterion,
} from "james/search/criterion";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { ValidationResult } from "common/validation";
import { StellarNetwork } from "james/stellar";
import {
  allFutureNetworks,
  criteriaFromToken,
} from "@mesh/common-js/dist/ledger";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useValidatedForm } from "hooks/useForm";
import { useErrorContext } from "context/Error";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { Token } from "james/ledger";
import {
  ListingRepository,
  ListingStateController,
  ListingUpdater,
} from "james/market";
import { TokenIdentifier } from "james/search/identifier";
import { ErrListingNotFoundCode } from "james/market/ListingRepository";
import { Listing, ListingState } from "james/market/Listing";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { networkFromFutureNetwork } from "james/ledger/Network";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import { enqueueSnackbar } from "notistack";
import {
  TransactionSucceededNotificationTypeName,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionFailedNotification,
  TransactionSubmissionResolutionFailedNotification,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification/Notification";
import { Notification } from "james/notification/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  FormData,
  FormUpdaterSpecsType,
  formDataValidationFunc,
  formUpdaterSpecs,
} from "./useValidatedForm";
import { BigNumber } from "bignumber.js";
import { Role } from "james/role";

dayjs.extend(utc);

export enum ViewMode {
  Undefined = "-",
  View = "view",
  Edit = "edit",
}

export type ListingContextType = {
  apiCallInProgress: boolean;

  viewMode: ViewMode;
  setEditViewMode: () => void;
  setViewViewMode: () => void;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  // data for form and validation
  formData: FormData;
  formDataValidationResult: ValidationResult;
  formUpdater: FormUpdaterSpecsType;

  performSave: () => void;
  retryListing: () => void;
  toggleActivation: () => void;

  reload: () => void;

  canEditListingInGroup: (groupID: string) => boolean;
};

export const defaultContext: ListingContextType = {
  apiCallInProgress: false,

  viewMode: ViewMode.View,
  setEditViewMode: () => null,
  setViewViewMode: () => null,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  // data for form and validation
  formData: {
    ledgerTokenViewModels: [],
    listing: new Listing(),
    listingCopy: new Listing(),
    smartInstrumentToList: new SmartInstrument(),
  },
  formDataValidationResult: {
    valid: false,
    fieldValidations: {},
  },
  formUpdater: formUpdaterSpecs,

  performSave: () => null,
  retryListing: () => null,
  toggleActivation: () => null,

  reload: () => null,

  canEditListingInGroup: () => false,
};

const ListingContext = React.createContext<ListingContextType>(defaultContext);

export const useListingContext = () => useContext(ListingContext);

export const ListingContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ children, system }) => {
  const { registerNotificationCallback } = useNotificationContext();
  const {
    financial: { smartInstrumentReader, smartInstrumentReaderUNSCOPED },
  } = useAPIContext();
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const navigate = useNavigate();
  const { authContext, viewConfiguration, myRoles } = useApplicationContext();
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();

  const [formData, formDataValidationResult, formUpdater] = useValidatedForm(
    formDataValidationFunc,
    undefined,
    formUpdaterSpecs,
    defaultContext.formData,
    new Set<string>([]),
    { skipTouchedFieldsOnValidation: true },
  );

  // prepare a function that checks if executing user has permission to edit listings in a particular group
  const canEditListingInGroup: (groupID: string) => boolean = useMemo(() => {
    const ids: Set<string> = new Set();

    (myRoles ?? ([] as Role[])).forEach((role) => {
      if (
        role.permissions.find((p) =>
          (
            [
              "ListAsset",
              "RetryListAsset",
              "ActivateListing",
              "DeactivateListing",
            ] as string[]
          ).includes(p.serviceName),
        )
      ) {
        ids.add(role.ownerID);
      }
    });

    return (groupID: string) => ids.has(groupID);
  }, [myRoles]);

  // ----------- associated data -----------
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoadError, setDataLoadError] = useState<string | null>(null);
  useEffect(() => {
    // do nothing if:
    // - required data already loaed
    // - if there was a data loading error
    // - component no longer mounted
    if (dataLoaded || dataLoadError || !isMounted()) {
      return;
    }

    // get the token for the asset that is to be listed from the url
    const urlTokenCode = searchParams.get("token-code");
    const urlTokenIssuer = searchParams.get("token-issuer");
    const urlTokenNetwork = Number(searchParams.get("token-network"));
    if (
      !(urlTokenCode && urlTokenIssuer && urlTokenNetwork) ||
      !allFutureNetworks.includes(urlTokenNetwork)
    ) {
      console.error("token not found in url");
      navigate({
        pathname: "/listing/primary-market/table",
      });
      return;
    }
    const futureTokenForPrimaryMarketListing = new FutureToken()
      .setCode(urlTokenCode)
      .setIssuer(urlTokenIssuer)
      .setNetwork(urlTokenNetwork);

    // Otherwise data loading needs to take place - do that now.
    (async () => {
      // prepare data required for listing
      let retrievedSmartInstrument: SmartInstrument = new SmartInstrument();
      let retrievedLedgerTokenViewModels: LedgerTokenViewModel[] = [];

      // fetch required data
      try {
        await Promise.all([
          // fetch the asset that is to be listed
          (async () => {
            const readSmartInstrument = (
              system
                ? await smartInstrumentReaderUNSCOPED.readOneSmartInstrumentUNSCOPED(
                    new ReadOneSmartInstrumentRequest()
                      .setContext(authContext.toFuture())
                      .setCriteriaList(
                        criteriaFromToken(futureTokenForPrimaryMarketListing),
                      ),
                  )
                : await smartInstrumentReader.readOneSmartInstrument(
                    new ReadOneSmartInstrumentRequest()
                      .setContext(authContext.toFuture())
                      .setCriteriaList(
                        criteriaFromToken(futureTokenForPrimaryMarketListing),
                      ),
                  )
            ).getSmartinstrument();
            if (!readSmartInstrument) {
              throw new TypeError("smart instrument is nil after reading");
            }
            retrievedSmartInstrument = readSmartInstrument;
          })(),

          // fetch all eligible ledger token view models that can be used
          // as the quote asset on the listing
          (async () => {
            retrievedLedgerTokenViewModels = (
              await LedgerTokenViewReader.Read({
                criteria: {
                  tokenCategory: TextNINListCriterion([
                    TokenCategory.InstrumentStablecoin,
                    TokenCategory.DigitalInstrument,
                    TokenCategory.LiquidityPoolShares,
                    TokenCategory.Unknown,
                  ]),
                  "token.network": TextListCriterion([
                    StellarNetwork.PublicNetwork,
                    StellarNetwork.TestSDFNetwork,
                  ]),
                },
              })
            ).models;

            // confirm at least 1 asset token found
            if (!retrievedLedgerTokenViewModels.length) {
              throw new Error("no token view models found");
            }
          })(),
        ]);
        if (isMounted()) {
          formUpdater.smartInstrumentToList(retrievedSmartInstrument);
          formUpdater.ledgerTokenViewModels(retrievedLedgerTokenViewModels);
          setDataLoaded(true);
        }
      } catch (e) {
        // if anything goes wrong store a data loaded error
        console.error("error initialising", e);
        setDataLoadError("Error Fetching Required Data.");
      }
    })();
  }, [dataLoaded, dataLoadError, isMounted]);

  // ----------- listing -----------
  const [listingLoaded, setListingLoaded] = useState(false);
  const [listingLoadError, setListingLoadError] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - data not yet loaded
      // - listing already loaed
      // - if there was an listing loading error
      // - component no longer mounted
      if (!dataLoaded || listingLoaded || listingLoadError || !isMounted()) {
        return;
      }

      // if the listing & subscription order book in the form are already set to those
      // identified by the token found in the URL then do nothing (unless the listing has been marked as not loaded - i.e. reload)
      if (
        formData.listing.token.isEqualTo(
          Token.fromFutureToken(formData.smartInstrumentToList.getToken()),
        ) &&
        listingLoaded
      ) {
        return;
      }

      // First try and fetch an associated listing.
      // If one is found then update the listing in the form data to this
      // listing, otherwise populate the listing in the form data with a
      // new listing.
      let listingForState: Listing | undefined;
      try {
        // fetch listing
        listingForState = (
          await ListingRepository.RetrieveListing({
            context: authContext,
            identifier: TokenIdentifier(
              Token.fromFutureToken(formData.smartInstrumentToList.getToken()),
            ),
          })
        ).listing;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === ErrListingNotFoundCode) {
          // if execution reaches here then the listing does not yet exist - prepare a new one
          listingForState = new Listing();
          listingForState.exchangeNetwork = networkFromFutureNetwork(
            formData.smartInstrumentToList.getToken()?.getNetwork() ??
              FutureNetwork.UNDEFINED_NETWORK,
          );
          listingForState.token = Token.fromFutureToken(
            formData.smartInstrumentToList.getToken(),
          );
          listingForState.state = "";
        } else {
          console.error("unexpected error retrieving listing", e);
          setListingLoadError(err.message);
          return;
        }
      }
      if (!listingForState) {
        setListingLoadError("Listing not correctly initialised.");
        return;
      }
      formUpdater.listing(listingForState);

      setListingLoaded(true);
    })();
  }, [dataLoaded, listingLoaded, listingLoadError, isMounted]);

  // ----------- view mode -----------
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Undefined);
  const setEditViewMode = () => {
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.Edit);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.Edit);
  };
  const setViewViewMode = () => {
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.View);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.View);
  };
  useEffect(() => {
    if (
      // if view mode is not undefined OR
      viewMode !== ViewMode.Undefined ||
      // data not yet loaded or there was an error while loading OR
      !dataLoaded ||
      dataLoadError ||
      // listing not yet loaded or that was an error while loading
      !listingLoaded ||
      listingLoadError
    ) {
      // then do nothing
      return;
    }
    // otherwise set the initial view mode

    // prepare url search params from the existing search params
    const query = new URLSearchParams(searchParams);

    if (formData.listing.id === "") {
      // if the listing ID is not set and the user has permission to
      // create a new listing (in the group that owns the smart instrument)
      if (
        viewConfiguration["Market Listing"]?.Write &&
        canEditListingInGroup(formData.smartInstrumentToList.getOwnerid())
      ) {
        // then edit mode should be active
        query.set("mode", ViewMode.Edit);
        navigate({
          pathname: location.pathname,
          search: query.toString(),
        });
        setViewMode(ViewMode.Edit);
      } else {
        // otherwise the user should not see this screen
        navigate({ pathname: "/builder/market-listing/table" });
      }
    } else {
      // if execution reaches here then the listing ID is set,
      // consider if a view mode has been given in the search query
      const viewModeFromURL = searchParams.get("mode");
      let newViewMode: ViewMode = viewModeFromURL
        ? (viewModeFromURL as ViewMode)
        : ViewMode.View;

      // if edit mode was requested and the user doesn't have permission to edit (in the group that owns the instrument) then
      // default to view mode
      if (
        newViewMode === ViewMode.Edit &&
        !viewConfiguration["Market Listing"]?.Write &&
        !canEditListingInGroup(formData.smartInstrumentToList.getOwnerid())
      ) {
        newViewMode = ViewMode.View;
      }

      // set view mode in state and url
      query.set("mode", newViewMode);
      navigate({
        pathname: location.pathname,
        search: query.toString(),
      });
      setViewMode(newViewMode);
    }
  }, [
    viewMode,
    formData.listing,
    listingLoaded,
    listingLoadError,
    dataLoaded,
    dataLoadError,
    viewConfiguration,
  ]);

  const clearInitialisationError = useCallback(() => {
    setDataLoadError(null);
    setListingLoadError(null);
  }, [dataLoadError, listingLoadError]);

  const reload = () => {
    setDataLoaded(false);
    setListingLoaded(false);
  };

  const [listingInProgress, setListingInProgress] = useState(false);
  const performListingCreation = async () => {
    setListingInProgress(true);
    let listingTransactionIDToMonitor: string;
    try {
      const { listing, transactionID } = await ListingStateController.ListAsset(
        {
          context: authContext,
          assetToken: Token.fromFutureToken(
            formData.smartInstrumentToList.getToken() ?? new FutureToken(),
          ),
          marketMechanisms: formData.listing.marketMechanisms,
          estimatedAnnualReturn: BigNumber("0"),
          investmentObjective: "",
          exchangeNetwork: formData.listing.exchangeNetwork,
        },
      );

      // update listing in form
      formUpdater.listing(listing);

      // move to view mode
      setViewViewMode();

      // get transaction ID to monitor
      listingTransactionIDToMonitor = transactionID;
    } catch (e) {
      console.error("error performing listing", e);
      errorContextDefaultWarningFeedback(e, "performing listing");
      setListingInProgress(false);
      reload();
      return;
    }
    enqueueSnackbar("Listing is in Progress", {
      variant: "success",
    });

    // register for listing notification feedback
    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: listingTransactionIDToMonitor,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === listingTransactionIDToMonitor
          ) {
            if (isMounted()) {
              reload();
            }
            enqueueSnackbar(`Listing Successful`, {
              variant: "success",
            });
          } else if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === listingTransactionIDToMonitor
          ) {
            if (isMounted()) {
              reload();
            }
            enqueueSnackbar("Listing Failed - Please Try Again", {
              variant: "warning",
            });
          } else if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === listingTransactionIDToMonitor
          ) {
            if (isMounted()) {
              reload();
            }
            enqueueSnackbar(
              "Something has gone wrong listing - Please Contact Support",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error("error registering to monitor listing transaction", e);
      enqueueSnackbar("Refresh to Monitor Listing Progress", {
        variant: "success",
      });
    }

    setListingInProgress(false);
  };

  const [listingUpdateInProgress, setListingUpdateInProgress] = useState(false);
  const performListingUpdate = async () => {
    setListingUpdateInProgress(true);
    try {
      const { listing } = await ListingUpdater.UpdateListingMarketMechanisms({
        context: authContext,
        listingID: formData.listing.id,
        marketMechanisms: formData.listing.marketMechanisms,
      });

      // update listing in form
      formUpdater.listing(listing);

      // move to view mode
      setViewViewMode();

      // notify that listing update was successful
      enqueueSnackbar("Listing Updated", {
        variant: "success",
      });
      setListingUpdateInProgress(false);
    } catch (e) {
      console.error("error performing listing", e);
      errorContextDefaultWarningFeedback(e, "performing listing");
      setListingUpdateInProgress(false);
      reload();
      return;
    }
  };

  const retryListing = async () => {
    setListingInProgress(true);
    let listingTransactionIDToMonitor: string;
    try {
      // invoke call to retry asset listing
      const { listing, transactionID } =
        await ListingStateController.RetryListAsset({
          context: authContext,
          assetToken: formData.listing.token,
        });
      formUpdater.listing(listing);
      listingTransactionIDToMonitor = transactionID;
    } catch (e) {
      console.error("error retrying listing", e);
      errorContextDefaultWarningFeedback(e, "retrying listing");
      setListingInProgress(false);
      reload();
      return;
    }

    // register for listing notification feedback
    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: listingTransactionIDToMonitor,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === listingTransactionIDToMonitor
          ) {
            if (isMounted()) {
              reload();
            }
            enqueueSnackbar(`Listing Successful`, {
              variant: "success",
            });
          } else if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === listingTransactionIDToMonitor
          ) {
            if (isMounted()) {
              reload();
            }
            enqueueSnackbar("Listing Failed - Please Try Again", {
              variant: "warning",
            });
          } else if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === listingTransactionIDToMonitor
          ) {
            if (isMounted()) {
              reload();
            }
            enqueueSnackbar(
              "Something has gone wrong listing - Please Contact Support",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error("error registering to monitor listing transaction", e);
      enqueueSnackbar("Refresh to Monitor Listing Progress", {
        variant: "success",
      });
    }

    setListingInProgress(false);
  };

  const toggleActivation = async () => {
    setListingInProgress(true);
    let toggledListing: Listing;
    try {
      if (formData.listing.state === ListingState.Active) {
        toggledListing = (
          await ListingStateController.DeactivateListing({
            context: authContext,
            listingID: formData.listing.id,
            lastActionAnnotation: "Deactivate From UI",
          })
        ).listing;
        enqueueSnackbar(`Deactivated`, {
          variant: "success",
        });
      } else {
        toggledListing = (
          await ListingStateController.ActivateListing({
            context: authContext,
            listingID: formData.listing.id,
            lastActionAnnotation: "Activate From UI",
          })
        ).listing;
        enqueueSnackbar(`Activated`, {
          variant: "success",
        });
      }

      // update listing in form
      formUpdater.listing(toggledListing);
    } catch (e) {
      console.error("error de/activating", e);
      errorContextDefaultWarningFeedback(e, "error de/activating");
    }
    setListingInProgress(false);
  };

  return (
    <ListingContext.Provider
      value={{
        apiCallInProgress: listingInProgress || listingUpdateInProgress,

        viewMode,
        setEditViewMode,
        setViewViewMode,

        initialised: dataLoaded && listingLoaded,
        initialisationError: dataLoadError || listingLoadError,
        clearInitialisationError,

        formData,
        formDataValidationResult,
        formUpdater,

        performSave: () => {
          // invode creation or update depending on if listing exists or not
          if (formData.listing.id === "") {
            return performListingCreation();
          } else {
            return performListingUpdate();
          }
        },
        retryListing,
        toggleActivation,

        reload,

        canEditListingInGroup,
      }}
    >
      {children}
    </ListingContext.Provider>
  );
};
