import {
  SmartInstrumentLegWrapper,
  allSmartInstrumentLegTypes,
  smartInstrumentLegTypeToString,
} from "@mesh/common-js/dist/financial";
import { SmartInstrumentLegType } from "@mesh/common-js/dist/financial/smartInstrumentLegType_pb";
import { SmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLeg_pb";
import {
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, alpha } from "@mui/system";
import React, { useState } from "react";
import {
  Close as DeleteIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import {
  BulletSmartInstrumentLegForm,
  FloatingRateSmartInstrumentLegForm,
} from "./components";
import { ViewMode, useBuilderContext } from "../../Context";

export type LegsProps = {
  height: number;
};

function legTypeAbbrev(leg: SmartInstrumentLegWrapper): string {
  switch (leg.smartInstrumentLegType) {
    case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE:
      return "BLT";

    case SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE:
      return "FLR";

    case SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE:
    default:
      return "-";
  }
}

export const LegsForm = (props: LegsProps) => {
  const {
    viewMode,
    apiCallInProgress,
    formData,
    formDataValidationResult,
    formDataUpdater,
  } = useBuilderContext();
  const [storedSelectedLegIdx, setSelectedLegIdx] = useState(
    formData.smartInstrument.getLegsList().length > 0 ? 0 : -1,
  );
  const selectedLegIdx =
    storedSelectedLegIdx > formData.smartInstrument.getLegsList().length - 1
      ? 0
      : storedSelectedLegIdx;

  return (
    <Box
      sx={(theme) => ({
        margin: theme.spacing(-2, -2, 0, -2),
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grapeLight,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        })}
      >
        <Box
          sx={(theme) => ({
            minHeight: 48,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1),
          })}
        >
          {!!formData.smartInstrument.getLegsList().length && (
            <Tabs
              scrollButtons
              visibleScrollbar
              // ensure that a leg is always selected - this code makes sure that a reasonable leg is still selected with a leg is deleted
              value={selectedLegIdx}
            >
              {formData.smartInstrument
                .getLegsList()
                .map(
                  (smartInstrumentLeg: SmartInstrumentLeg, legIdx: number) => {
                    const leg = new SmartInstrumentLegWrapper(
                      smartInstrumentLeg,
                    );
                    return (
                      <Tab
                        id={`smartInstrumentForm-leg${legIdx}-tab`}
                        key={legIdx}
                        value={legIdx}
                        label={
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: theme.spacing(0.5),
                            })}
                          >
                            <Tooltip placement="top" title={leg.name}>
                              <Typography>{legTypeAbbrev(leg)}</Typography>
                            </Tooltip>
                            {viewMode === ViewMode.Edit && (
                              <Tooltip placement="top" title={"Delete Leg"}>
                                <IconButton
                                  id={`smartInstrumentForm-leg${legIdx}-delete-tabIconButon`}
                                  onClick={() =>
                                    formDataUpdater.removeLeg(legIdx)
                                  }
                                  sx={{
                                    width: "24px",
                                    height: "24px",
                                    padding: "4px",
                                  }}
                                >
                                  <DeleteIcon sx={{ fontSize: "16px" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        }
                        onClick={() => setSelectedLegIdx(legIdx)}
                      />
                    );
                  },
                )}
            </Tabs>
          )}
          {viewMode === ViewMode.Edit && (
            <TextField
              size="small"
              sx={(theme) => ({
                width: 90,
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1),
              })}
              id={"smartInstrumentForm-potentialLegType-selectField"}
              disabled={apiCallInProgress}
              label="Add Leg"
              select
              placeholder="Select..."
              value={SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE}
              onChange={(e) => {
                formDataUpdater.addLeg(
                  Number(e.target.value) as SmartInstrumentLegType,
                );
                setSelectedLegIdx(
                  formData.smartInstrument.getLegsList().length - 1,
                );
              }}
            >
              {allSmartInstrumentLegTypes.map((v) => {
                return (
                  <MenuItem key={v} value={v}>
                    {smartInstrumentLegTypeToString(v)}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Box>
      </Box>
      <Box
        className="meshScroll"
        sx={{
          height: props.height - 50,
          overflowY: "auto",
          overflowX: "hidden",
          display: "grid",
          gridTemplateColumns: "1fr auto",
        }}
      >
        {(() => {
          const rawLeg = formData.smartInstrument.getLegsList()[selectedLegIdx];
          if (!rawLeg) {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <FaceIcon
                    sx={(theme) => ({
                      fontSize: 110,
                      color: alpha(theme.palette.background.default, 0.5),
                    })}
                  />
                  <Typography
                    color="secondary"
                    variant="h4"
                    children="No Legs Added Yet!"
                  />
                  <Typography variant="body2" children={"Add at Least 1 Leg"} />
                </Box>
              </Box>
            );
          }
          const leg = new SmartInstrumentLegWrapper(rawLeg);

          switch (leg.smartInstrumentLegType) {
            case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE: {
              const bulletSmartInstrumentLeg =
                leg.smartInstrumentLeg.getBulletsmartinstrumentleg();
              return bulletSmartInstrumentLeg ? (
                <BulletSmartInstrumentLegForm
                  disabled={apiCallInProgress}
                  readOnly={viewMode === ViewMode.View}
                  leg={bulletSmartInstrumentLeg}
                  formDataValidationResult={formDataValidationResult}
                  ledgerTokenViewModels={formData.assetTokenViewModels}
                  onChange={(updatedLeg) => {
                    formDataUpdater.updateLeg({
                      smartInstrumentLeg:
                        new SmartInstrumentLeg().setBulletsmartinstrumentleg(
                          updatedLeg,
                        ),
                      legIdx: selectedLegIdx,
                    });
                  }}
                  timezone={formData.smartInstrument.getTimezone()}
                  smartInstrumentIssueDate={formData.smartInstrument.getIssuedate()}
                />
              ) : (
                "Leg is Not Set."
              );
            }

            case SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE: {
              const floatingratesmartinstrumentleg =
                leg.smartInstrumentLeg.getFloatingratesmartinstrumentleg();
              return floatingratesmartinstrumentleg ? (
                <FloatingRateSmartInstrumentLegForm
                  disabled={apiCallInProgress}
                  readOnly={viewMode === ViewMode.View}
                  leg={floatingratesmartinstrumentleg}
                  formDataValidationResult={formDataValidationResult}
                  ledgerTokenViewModels={formData.assetTokenViewModels}
                  onChange={(updatedLeg) => {
                    formDataUpdater.updateLeg({
                      smartInstrumentLeg:
                        new SmartInstrumentLeg().setFloatingratesmartinstrumentleg(
                          updatedLeg,
                        ),
                      legIdx: selectedLegIdx,
                    });
                  }}
                  timezone={formData.smartInstrument.getTimezone()}
                  smartInstrumentIssueDate={formData.smartInstrument.getIssuedate()}
                />
              ) : (
                "Leg is Not Set."
              );
            }

            default:
              return "Invalid Leg Type.";
          }
        })()}
      </Box>
    </Box>
  );
};
