import { useEffect } from "react";
import { useExchangeDashboardStore } from "../store";
import { useExchangeStore } from "../../../store";
import { Reader } from "james/views/marketListingView";
import { TextExactCriterion } from "james/search/criterion";
import { useLocation, useNavigate } from "react-router-dom";
import { LedgerAccountCategory, Token } from "james/ledger";
import { useAccountContext } from "context/Account/Account";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { useApplicationContext } from "context/Application/Application";
import { Balance } from "james/stellar/Account";
import { MechanismType } from "james/market";

export const useInitialDataFetch = () => {
  const store = useExchangeDashboardStore();
  const { stellarAccountContext } = useAccountContext();
  const exchangeStore = useExchangeStore();
  const { authContext } = useApplicationContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Fetch market listing view model
  // only if not already set
  // this happens on refreshing the browser or navigating directly to the link
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!exchangeStore.marketListingPair) {
      const list = pathname.split("/");
      const baseCode = list[list.length - 2];
      const counterCode = list[list.length - 1];

      timeout = setTimeout(async () => {
        try {
          const response = await Reader.ReadOne({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion(baseCode),
            },
          });

          let counterToken = new Token();
          response.model.listingMarketMechanisms.forEach((mech) =>
            mech.quoteParameters.forEach((qp) => {
              if (qp.quoteToken.code === counterCode) {
                counterToken = qp.quoteToken;
              }
            }),
          );

          exchangeStore.setMarketListingPair({
            base: response.model.token,
            counter: counterToken,
          });

          store.updateTradeCardState.setTradeCardForm(
            store.tradeCardState.price
              .setToken(counterToken.toFutureToken())
              .setValue(new Decimal().setValue(0)),
            new FutureAmount()
              .setToken(response.model.token.toFutureToken())
              .setValue(new Decimal().setValue(0)),
            store.tradeCardState.estimatedTotal
              .setToken(counterToken.toFutureToken())
              .setValue(new Decimal().setValue(0)),
          );
          exchangeStore.setSelectedMarketListing(response.model);
          store.updateTradeCardState.setQuoteParemeter(
            response.model.listingMarketMechanisms
              .find((m) => m.type === MechanismType.LimitOrder)
              ?.quoteParameters.find((qp) =>
                qp.quoteToken.isEqualTo(counterToken),
              ),
          );
        } catch (e) {
          console.error(e);
          navigate("");
        }
      });
    } else {
      const pair = exchangeStore.marketListingPair;
      store.updateTradeCardState.setTradeCardForm(
        store.tradeCardState.price
          .setToken(pair.counter.toFutureToken())
          .setValue(new Decimal().setValue(0)),
        new FutureAmount()
          .setToken(pair.base.toFutureToken())
          .setValue(new Decimal().setValue(0)),
        store.tradeCardState.estimatedTotal
          .setToken(pair.counter.toFutureToken())
          .setValue(new Decimal().setValue(0)),
      );
      store.updateTradeCardState.setQuoteParemeter(
        exchangeStore.selectedMarketListing?.listingMarketMechanisms
          .find((v) => v.type === MechanismType.LimitOrder)
          ?.quoteParameters.find((qp) => qp.quoteToken.isEqualTo(pair.counter)),
      );
    }

    return () => clearTimeout(timeout);
  }, [pathname, exchangeStore.marketListingPair]);

  // Check balances
  useEffect(() => {
    if (!exchangeStore.marketListingPair) return;
    const balance = stellarAccountContext.accounts
      .find((v) => {
        if (v.accountCategory() === LedgerAccountCategory.Trading) return true;
      })
      ?.balances.find(
        (v) =>
          v.tokenViewModel.token.code ===
          exchangeStore.marketListingPair?.counter.code,
      );

    const baseBalance = stellarAccountContext.accounts
      .find((v) => {
        if (v.accountCategory() === LedgerAccountCategory.Trading) return true;
      })
      ?.balances.find(
        (v) =>
          v.tokenViewModel.token.code ===
          exchangeStore.marketListingPair?.base.code,
      );

    store.updateTradeCardState.setBalance({
      0: balance ?? new Balance(),
      1: baseBalance ?? new Balance(),
    });
    store.updateTradeCardState.setSourceAccountID(
      stellarAccountContext.accounts.find((v) => {
        if (v.accountCategory() === LedgerAccountCategory.Trading) return true;
      })?.ledgerID ?? "",
    );

    if (stellarAccountContext.accounts.length != 0) {
      store.setLoading(false);
    }
  }, [stellarAccountContext.loading, exchangeStore.marketListingPair]);
};
