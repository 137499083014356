import React from "react";
import { useSubscriptionOrderBookContext, ViewMode } from "../../Context";
import { MechanismType } from "james/market";
import { TextNumField } from "components/FormFields";
import { unitToString } from "@mesh/common-js/dist/financial";
import {
  Box,
  Button,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import dayjs from "dayjs";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Info as InfoIcon } from "@mui/icons-material";
import { SubscriptionOrderBookStateChip } from "components/MarketSubscriptions/Chips";

export const SubscriptionOrderBookCreateForm = () => {
  const {
    formData,
    formUpdater,
    formDataValidationResult,
    viewMode,
    apiCallInProgress,
    smartInstrumentUnit,
    createBook,
  } = useSubscriptionOrderBookContext();

  const editMode = viewMode === ViewMode.Edit;
  const disabled = apiCallInProgress;

  const getFieldValidation = (field: string) => {
    return formDataValidationResult.fieldValidations[
      `${MechanismType.Subscription}-${field}`
    ];
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <SubscriptionOrderBookStateChip
          state={formData.subscriptionOrderBook.state}
        />
        {editMode && (
          <Box sx={{ marginLeft: "auto" }}>
            <Tooltip
              placement="top"
              title={(() => {
                if (!formDataValidationResult.valid) {
                  const problems: string[] = [];
                  Object.keys(
                    formDataValidationResult.fieldValidations,
                  ).forEach((k) => {
                    if (formDataValidationResult.fieldValidations[k]) {
                      problems.push(
                        `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                      );
                    }
                  });
                  return (
                    <Box>
                      <Typography>
                        All problems need to be resolved before changes can be
                        saved:
                      </Typography>
                      <ul>
                        {problems.map((p, idx) => (
                          <li key={idx}>{p}</li>
                        ))}
                      </ul>
                    </Box>
                  );
                }
              })()}
            >
              <span>
                <Button
                  id={"subscriptionOrderBook-create-button"}
                  variant="contained"
                  color="primary"
                  disabled={
                    !formDataValidationResult.valid || apiCallInProgress
                  }
                  onClick={createBook}
                >
                  Create Book
                </Button>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "center",
        })}
      >
        <TextNumField
          id={
            "marketListing-mechanisms-subscriptionMechanism-unitPrice-textNumField"
          }
          readOnly={!editMode}
          disabled={disabled}
          fullWidth
          noDecimalPlaces={7}
          label={`${unitToString(smartInstrumentUnit)} Price`}
          disallowNegative
          value={formData.subscriptionOrderBook.unitPrice.value}
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            formData.subscriptionOrderBook.unitPrice =
              formData.subscriptionOrderBook.unitPrice.setValue(e.target.value);
            formUpdater.subscriptionOrderBook(formData.subscriptionOrderBook);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {formData.subscriptionOrderBook.unitPrice.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Cost of a single ${unitToString(smartInstrumentUnit)}`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={!!getFieldValidation("unitPrice")}
          helperText={getFieldValidation("unitPrice")}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "start",
        })}
      >
        <TextNumField
          id={
            "marketListing-mechanisms-subscriptionMechanism-subscriptionAmount-textNumField"
          }
          readOnly={!editMode}
          disabled={disabled}
          fullWidth
          noDecimalPlaces={7}
          label={"Subscription Amount"}
          disallowNegative
          value={formData.subscriptionOrderBook.subscriptionAmount.value}
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            formData.subscriptionOrderBook.subscriptionAmount =
              formData.subscriptionOrderBook.subscriptionAmount.setValue(
                e.target.value,
              );
            formUpdater.subscriptionOrderBook(formData.subscriptionOrderBook);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {formData.subscriptionOrderBook.subscriptionAmount.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip placement="top" title={"Target Amount to Raise"}>
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={!!getFieldValidation("subscriptionAmount")}
          helperText={getFieldValidation("subscriptionAmount")}
        />
        <TextNumField
          id={
            "marketListing-mechanisms-subscriptionMechanism-unitsToSell-textNumField"
          }
          readOnly
          fullWidth
          noDecimalPlaces={7}
          label={`${unitToString(smartInstrumentUnit)}'s To Sell`}
          disallowNegative
          value={
            formData.subscriptionOrderBook.subscriptionAmount.value
              .dividedBy(formData.subscriptionOrderBook.unitPrice.value)
              .isFinite()
              ? formData.subscriptionOrderBook.subscriptionAmount.value.dividedBy(
                  formData.subscriptionOrderBook.unitPrice.value,
                )
              : undefined
          }
          sx={{ maxWidth: 320 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {formData.subscriptionOrderBook.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Subscription Amount / ${unitToString(smartInstrumentUnit)} Price`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={!!getFieldValidation("overSubscriptionAmount")}
          helperText={getFieldValidation("overSubscriptionAmount")}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
          alignItems: "center",
        })}
      >
        <TextNumField
          id={"marketListing-mechanisms-subscriptionMechanism-textNumField"}
          readOnly={!editMode}
          disabled={disabled}
          fullWidth
          noDecimalPlaces={7}
          label={"Over Subscription Amount"}
          disallowNegative
          value={formData.subscriptionOrderBook.overSubscriptionAmount.value}
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            formData.subscriptionOrderBook.overSubscriptionAmount =
              formData.subscriptionOrderBook.overSubscriptionAmount.setValue(
                e.target.value,
              );
            formUpdater.subscriptionOrderBook(formData.subscriptionOrderBook);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {
                  formData.subscriptionOrderBook.overSubscriptionAmount.token
                    .code
                }
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={"Absolute Possible Maximum Amount to Raise"}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={!!getFieldValidation("overSubscriptionAmount")}
          helperText={getFieldValidation("overSubscriptionAmount")}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "start",
        })}
      >
        <DateTimeField
          id={"marketListing-subscriptionOrderBook-openDate-dateTimeField"}
          sx={{ width: 300 }}
          disabled={disabled}
          readOnly={!editMode}
          label={"Open Date"}
          value={dayjsToProtobufTimestamp(
            dayjs(formData.subscriptionOrderBook.openDate),
          )}
          minDate={dayjs().add(1, "day")}
          maxDateTime={dayjs(formData.subscriptionOrderBook.closeDate).subtract(
            1,
            "day",
          )}
          onChange={(newValue) => {
            formData.subscriptionOrderBook.openDate = protobufTimestampToDayjs(
              newValue ?? new Timestamp(),
            ).format();
            formUpdater.subscriptionOrderBook(formData.subscriptionOrderBook);
          }}
          error={!!getFieldValidation("openDate")}
          helperText={getFieldValidation("openDate")}
        />
        <DateTimeField
          id={"marketListing-subscriptionOrderBook-closeDate-dateTimeField"}
          sx={{ width: 300 }}
          disabled={disabled}
          readOnly={!editMode}
          label={"Close Date"}
          minDate={dayjs(formData.subscriptionOrderBook.openDate).add(1, "day")}
          maxDateTime={dayjs(formData.subscriptionOrderBook.settlementDate)}
          value={dayjsToProtobufTimestamp(
            dayjs(formData.subscriptionOrderBook.closeDate),
          )}
          onChange={(newValue) => {
            formData.subscriptionOrderBook.closeDate = protobufTimestampToDayjs(
              newValue ?? new Timestamp(),
            ).format();
            formUpdater.subscriptionOrderBook(formData.subscriptionOrderBook);
          }}
          error={!!getFieldValidation("closeDate")}
          helperText={getFieldValidation("closeDate")}
        />
        <Tooltip
          placement="top"
          title={`Set to the Issue Date of the ${unitToString(smartInstrumentUnit)}`}
        >
          <span>
            <DateTimeField
              id={
                "marketListing-subscriptionOrderBook-settlementDate-dateTimeField"
              }
              sx={{ maxWidth: 300 }}
              readOnly
              label={"Settlement Date"}
              value={dayjsToProtobufTimestamp(
                dayjs(formData.subscriptionOrderBook.settlementDate),
              )}
            />
          </span>
        </Tooltip>
      </Box>
    </>
  );
};
