import { InstrumentRiskProfile } from "james/financial";
import { Token } from "james/ledger";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";
import dayjs from "dayjs";
import { ListingState } from "james/market/Listing";
import { Mechanism, MechanismType, QuoteParameter } from "james/market";
import { LedgerNetwork } from "james/ledger/Network";
import { MediaImage } from "james/media/Image";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";

export enum AssetType {
  Share = "Share",
  RightsToAShare = "Rights to a Share",
  PreferenceShare = "Preference Share",
  RightsToAPreferenceShare = "Rights to a Preference Share",
  Bond = "Bond",
  RightsToABond = "Rights to a Bond",
  ETF = "ETF",
  RightsToAnETF = "Rights to an ETF",
  ETN = "ETN",
  RightsToAnETN = "Rights to an ETN",
  AMC = "AMC",
  RightsToAnAMC = "Rights to an AMC",
  UnitTrust = "Unit Trust",
  RightsToAUnitTrust = "Rights to a Unit Trust",
  CryptoCurrency = "Crypto Currency",
  RightsToACryptoCurrency = "Rights to a Crypto Currency",
  FiatCurrency = "Fiat Currency",
  RightsToAFiatCurrency = "Rights to a Fiat Currency",
  YieldBearingStablecoin = "Yield Bearing Stablecoin",
  Commodity = "Commodity",
  RightsToACommodity = "Rights to a Commodity",
  Other = "Other",
}

export const AllAssetTypes: AssetType[] = [
  AssetType.Share,
  AssetType.RightsToAShare,
  AssetType.PreferenceShare,
  AssetType.RightsToAPreferenceShare,
  AssetType.Bond,
  AssetType.RightsToABond,
  AssetType.ETF,
  AssetType.RightsToAnETF,
  AssetType.ETN,
  AssetType.RightsToAnETN,
  AssetType.AMC,
  AssetType.RightsToAnAMC,
  AssetType.UnitTrust,
  AssetType.RightsToAUnitTrust,
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.FiatCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
  AssetType.Commodity,
  AssetType.RightsToACommodity,
  AssetType.Other,
];

export const ModelTypeName = "mesh::marketListingView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;
  public id = "";
  public ownerID = "";

  public listingState: ListingState | "" = "";

  // ListingID is a reference to the market.Listing with which the Model is associated.
  public listingID = "";
  public listingMarketMechanisms: Mechanism[] = [];
  public listingLastActionAnnotation = "";
  public isin = "";
  public exchangeCode = "";

  public token: Token = new Token();
  public tokenIconURL = "";

  // AssetID is a reference to an implementation of the market.Asset interface
  // whose issuance ledger.Token is associated with the market.Listing referenced by
  // ListingID.
  // e.g. this could the ID of a financial.DigitalETF or financial.ETFStablecoin
  // (both of which implement the market.Asset interface)
  public assetID = "";
  public assetOwnerID = "";
  public assetOwnerClientShortName = "";
  public assetName = "";
  public assetShortName = "";
  public assetType: AssetType | "" = "";
  public assetIssueDate: string = dayjs().format();
  public assetFractionalisationAllowed: boolean = false;

  public instrumentRiskProfile: InstrumentRiskProfile | "" = "";
  public instrumentRiskProfileDescription = "";

  // ReturnDescription provides a description that gives context to the ReturnValue.
  // This is different depending on what return information was available on the
  // associated market.Asset.
  // e.g.:
  // 	- Last 12m
  // 	- June 2020
  // 	- Est. Annual
  public returnDescription = "";

  // ReturnValue is a percentage value that, together with ReturnDescription, gives an indication
  // of what sort of return the market.Asset may provide.
  // e.g.:
  // 	- 12% --> Last 12m
  // 	- -3.2% --> June 2020
  // 	- 5.23% --> Est. Annual
  public returnValue = "";

  public priority = 0;

  public exchangeNetwork: LedgerNetwork | "" = "";

  public marketSubscriptionOrderBookViewModel: MarketSubscriptionOrderBookViewModel | null =
    null;

  public issuerLogo: MediaImage = new MediaImage();

  public frequency: Frequency = Frequency.MONTHLY_FREQUENCY;

  public newInstrumentModel: boolean = false;

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.ownerID = model.ownerID;
    this.listingState = model.listingState;
    this.listingID = model.listingID;
    this.listingMarketMechanisms = model.listingMarketMechanisms.map(
      (m) => new Mechanism(m),
    );
    this.listingLastActionAnnotation = model.listingLastActionAnnotation;
    this.isin = model.isin;
    this.exchangeCode = model.exchangeCode;
    this.token = new Token(model.token);
    this.tokenIconURL = model.tokenIconURL;
    this.assetID = model.assetID;
    this.assetOwnerID = model.assetOwnerID;
    this.assetOwnerClientShortName = model.assetOwnerClientShortName;
    this.assetName = model.assetName;
    this.assetShortName = model.assetShortName;
    this.assetType = model.assetType;
    this.assetIssueDate = model.assetIssueDate;
    this.assetFractionalisationAllowed = model.assetFractionalisationAllowed;
    this.instrumentRiskProfile = model.instrumentRiskProfile;
    this.instrumentRiskProfileDescription =
      model.instrumentRiskProfileDescription;
    this.returnDescription = model.returnDescription;
    this.returnValue = model.returnValue;
    this.priority = model.priority;
    if (model.marketSubscriptionOrderBookViewModel) {
      this.marketSubscriptionOrderBookViewModel =
        new MarketSubscriptionOrderBookViewModel(
          model.marketSubscriptionOrderBookViewModel,
        );
    }
    this.issuerLogo = new MediaImage(model.issuerLogo);
    this.frequency = model.frequency;
    this.newInstrumentModel = model.newInstrumentModel;
  }

  getMarketMechanismQuoteParameter(
    mechanismType: MechanismType,
    quoteToken: Token,
  ): QuoteParameter {
    // find given mechanism type
    for (const marketMechanism of this.listingMarketMechanisms) {
      if (marketMechanism.type === mechanismType) {
        // find quote parameter for given quote token
        for (const quoteParameter of marketMechanism.quoteParameters) {
          if (quoteParameter.quoteToken.isEqualTo(quoteToken)) {
            return quoteParameter;
          }
        }

        // matching quote parameter not found
        throw new Error(`quote token '${quoteToken.string()}' not supported`);
      }
    }

    // matching market mechanism not found
    throw new Error(`market mechanism ${mechanismType} not supported`);
  }
}
