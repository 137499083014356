import { Permission } from "james/security";

export enum RoleType {
  MeshCLIRole = "Mesh CLI",
  PlatformAdministratorRole = "Platform Administrator",
  AdministratorRole = "Administrator",
  IssuerRole = "Issuer",
  IssuerDealer = "Issuer Dealer",
  InvestorDealer = "Investor Dealer",
  InvestorOperationsRole = "Investor Operations",
  IssuerOperationsRole = "Issuer Operations",
  ManagingCompanyOperationsRole = "Managing Company Operations",
  ComplianceOfficerRole = "Compliance Officer",
  ManagingCompanyRootOnlyRole = "MC Root Only",
  MarketManagerRole = "Market Manager",
  FinancialOfficerRole = "Financial Officer",
  ConsistencyControllerRole = "Consistency Controller",
  ConsistencyViewerRole = "Consistency Viewer",
  InvestorRole = "Investor",
  // limit order specific
  LimitOrderSuperAdmin = "Limit Order Super Admin",
  LimitOrderAdmin = "Limit Order Admin",
  LimitOrderTrader = "Limit Order Trader",
}

export const AllRoleTypes: RoleType[] = [
  RoleType.MeshCLIRole,
  RoleType.PlatformAdministratorRole,
  RoleType.AdministratorRole,
  RoleType.IssuerRole,
  RoleType.IssuerDealer,
  RoleType.InvestorDealer,
  RoleType.InvestorOperationsRole,
  RoleType.IssuerOperationsRole,
  RoleType.ManagingCompanyOperationsRole,
  RoleType.ComplianceOfficerRole,
  RoleType.ManagingCompanyRootOnlyRole,
  RoleType.MarketManagerRole,
  RoleType.FinancialOfficerRole,
  RoleType.ConsistencyControllerRole,
  RoleType.ConsistencyViewerRole,
  RoleType.InvestorRole,
];

export const RoleTypeName = "mesh::role/Role";

export class Role {
  public ["@type"]: string = RoleTypeName;

  public id = "";

  public name = "";

  public ownerID = "";

  public permissions: Permission[] = [];

  constructor(role?: Role) {
    if (!role) {
      return;
    }
    this.id = role.id;
    this.name = role.name;
    this.ownerID = role.ownerID;
    this.permissions = role.permissions.map((p) => new Permission(p));
  }
}
