import { create } from "zustand";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";
import { ReadManyModelRequest } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReader_meshproto_pb";
import { newUint32ListCriterion } from "@mesh/common-js/dist/search";
import { LimitOrderState } from "@mesh/common-js/dist/market/limitOrder_pb";

export enum TabOption {
  MyOffers,
  History,
}

export interface MyOffersStore {
  loading: boolean;
  searchingOffers: boolean;
  selectedTab: TabOption;
  marketLimitOrderViewModels: Model[];
  total: number;
  request: ReadManyModelRequest;
  cancelingOrder: boolean;
}

export interface MyOffersActions {
  setSelectedTab: (val: TabOption) => void;
  setMarketLimitOrderViewModels: (val: Model[]) => void;
  setTotal: (val: number) => void;
  setRequest: (val: ReadManyModelRequest) => void;
  setLoading: (val: boolean) => void;
  setSearchingOffers: (val: boolean) => void;
  setCancelingOrder: (val: boolean) => void;
}

const initialMyOffersStoreState: MyOffersStore = {
  selectedTab: TabOption.MyOffers,
  marketLimitOrderViewModels: [],
  total: 0,
  request: new ReadManyModelRequest().setCriteriaList([
    newUint32ListCriterion("state", [
      LimitOrderState.SUBMISSION_IN_PROGRESS,
      LimitOrderState.CANCELLATION_IN_PROGRESS,
      LimitOrderState.COMPLETE_IN_PROGRESS,
      LimitOrderState.OPEN,
    ]),
  ]),
  loading: true,
  searchingOffers: false,
  cancelingOrder: false,
};

export const useMyOffersStore = create<MyOffersStore & MyOffersActions>(
  (set) => ({
    ...initialMyOffersStoreState,
    setSelectedTab: (val) => {
      set({
        selectedTab: val,
      });
    },
    setMarketLimitOrderViewModels: (val: Model[]) => {
      set({
        marketLimitOrderViewModels: val,
      });
    },
    setTotal: (val: number) => set({ total: val }),
    setRequest: (val: ReadManyModelRequest) => set({ request: val }),
    setLoading: (val: boolean) => set({ loading: val }),
    setSearchingOffers: (val: boolean) => set({ searchingOffers: val }),
    setCancelingOrder: (val: boolean) => set({ cancelingOrder: val }),
  }),
);
