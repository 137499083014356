import React, { useState } from "react";
import { Box, useTheme, Button, CircularProgress } from "@mui/material";
import { BETable, RowType } from "@mesh/common-js-react/dist/Tables";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { ReadManyModelRequest } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReader_meshproto_pb";
import { useApplicationContext } from "context/Application/Application";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { limitOrderStateToString } from "@mesh/common-js/dist/market/limitOrderState";
import { FullUpdateRequest } from "@mesh/common-js/dist/views/marketLimitOrderView/modelUpdater_pb";
import { ResolveIDStateRequest } from "@mesh/common-js/dist/market/limitOrderStateController_pb";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import {
  newORCriterion,
  newTextSubstringCriterion,
} from "@mesh/common-js/dist/search";
import { Criteria } from "@mesh/common-js/dist/search/Criteria";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/src/i8n/timezone_pb";

export const LimitOrder = () => {
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const theme = useTheme();
  const { authContext, viewConfiguration } = useApplicationContext();
  const { views, market } = useAPIContext();
  const [criteria, setCriteria] = useState<Criterion[]>([]);
  const [query, setQuery] = useState(new Query().setLimit(10).setOffset(0));
  const [interval, setInterval] = useState(10);
  const [loading, setLoading] = useState(false);
  const canResolve = viewConfiguration.Trading?.["Limit Order"]?.Resolve;
  const canUpdateViews = viewConfiguration.Trading?.["Limit Order"]?.ViewModel;
  const [selectedLimitOrder, setSelectedLimitOrder] = useState<Model>();

  const searchLimitOrders = async (request: ReadManyModelRequest) => {
    try {
      const response =
        await views.marketLimitOrderViewModelUnscopedReader.readManyModelUNSCOPED(
          request
            .setContext(authContext.toFuture())
            .setCriteriaList(criteria)
            .setQuery(query),
        );

      return response;
    } catch (err) {
      errorContextDefaultErrorFeedback(err);
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100% - 80px)",
      }}
    >
      <BETable
        tableContainerClassName="meshScroll"
        requestFunction={async () => {
          const response = await searchLimitOrders(new ReadManyModelRequest());
          if (!response) {
            return {
              models: [],
              total: 0,
            };
          }
          return {
            models: response.getRecordsList(),
            total: response.getTotal(),
          };
        }}
        columns={columns}
        title={"Limit Orders"}
        interval={query.getLimit()}
        criteria={new Criteria()}
        onPageChange={(prevPage, page) => {
          setQuery(query.setOffset(page * interval));
          searchLimitOrders(new ReadManyModelRequest());
        }}
        onRowsPerPageChange={(rows) => {
          setQuery(query.setOffset(0).setLimit(rows));
          setInterval(rows);
          searchLimitOrders(new ReadManyModelRequest());
        }}
        query={query}
        height={500}
        onSelect={(row) => {
          setSelectedLimitOrder(row as Model);
        }}
        filters={(_setCriteria) => {
          return (
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="TextSearch"
                onChange={(e) => {
                  setCriteria([
                    newORCriterion([
                      newTextSubstringCriterion("number", e.target.value),
                      newTextSubstringCriterion("type", e.target.value),
                      newTextSubstringCriterion("state", e.target.value),
                    ]),
                  ]);
                  _setCriteria([
                    newORCriterion([
                      newTextSubstringCriterion("number", e.target.value),
                      newTextSubstringCriterion("type", e.target.value),
                      newTextSubstringCriterion("state", e.target.value),
                    ]),
                  ]);
                }}
              />
            </Box>
          );
        }}
        actions={
          <>
            {canResolve && selectedLimitOrder && (
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    setLoading(true);
                    await market.limitOrderStateControllerPromiseClient.resolveIDState(
                      new ResolveIDStateRequest()
                        .setContext(authContext.toFuture())
                        .setLimitorderid(selectedLimitOrder.getLimitorderid())
                        .setManualinvocation(true),
                    );
                    setLoading(false);
                  } catch (e) {
                    setLoading(false);
                    errorContextDefaultErrorFeedback(e);
                  }
                }}
              >
                Resolve State
              </Button>
            )}
            {canUpdateViews && (
              <Button
                variant="outlined"
                disabled={loading}
                onClick={async () => {
                  try {
                    setLoading(true);
                    await views.marketLimitOrderViewUpdaterPromiseClient.fullUpdate(
                      new FullUpdateRequest().setContext(
                        authContext.toFuture(),
                      ),
                    );
                    setLoading(false);
                  } catch (e) {
                    setLoading(false);
                    errorContextDefaultErrorFeedback(e);
                  }
                }}
              >
                {loading && <CircularProgress size={18} />}
                Full Update
              </Button>
            )}
          </>
        }
        refresh={() => {
          setQuery(new Query().setLimit(10).setOffset(0));
          setCriteria([]);
          searchLimitOrders(new ReadManyModelRequest());
        }}
        sx={{
          height: "100%",
          ".table-body": {
            border: 2,
            backgroundColor: theme.palette.custom.cardInner,
          },
        }}
      />
    </Box>
  );
};

type ColumnType = Omit<Model.AsObject, "ownerid" | "id" | "vatrate">;

const columns: RowType<Model, ColumnType> = {
  number: {
    title: "Number",
    renderCell: (rowData: Model) => {
      return rowData.getNumber();
    },
  },
  limitorderid: {
    title: "ID",
    renderCell: (rowData: Model) => {
      return rowData.getLimitorderid();
    },
  },
  stellarofferid: {
    title: "Stellar Offer Id",
    renderCell: (rowData: Model) => {
      return rowData.getStellarofferid();
    },
  },

  submittedat: {
    title: "Submitted At",
    renderCell: (rowData: Model) => {
      return protobufTimestampToDayjs(
        rowData.getSubmittedat() ?? new Timestamp(),
      )
        .tz(timezoneToString(Timezone.SAST_TIMEZONE))
        .format("DD MMM YYYY, HH:mm:ss");
    },
  },
  type: {
    title: "Type",
    renderCell: (rowData: Model) => {
      return rowData.getType();
    },
  },
  price: {
    title: "Price",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getPrice())} />
      );
    },
  },
  baseamount: {
    title: "Base Amount",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getBaseamount())} />
      );
    },
  },
  feeamount: {
    title: "Fee Amount",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getFeeamount())} />
      );
    },
  },
  quoteamount: {
    title: "Quote Amount",
    renderCell: (rowData: Model) => {
      return (
        <Amount
          amount={AmountType.fromFutureAmount(rowData.getQuoteamount())}
        />
      );
    },
  },
  amountfilled: {
    title: "Amount Filled",
    renderCell: (rowData: Model) => {
      return (
        <Amount
          amount={AmountType.fromFutureAmount(rowData.getAmountfilled())}
        />
      );
    },
  },
  state: {
    title: "State",
    renderCell: (rowData: Model) => {
      return limitOrderStateToString(rowData.getState());
    },
  },
};
